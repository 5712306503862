







import { Component } from 'vue-property-decorator';
import { Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { ValidationProvider } from 'vee-validate';
import ChampMixin from '@/formsGenerator/mixins/ChampMixin';

@Component({
    name: 'LabelField',
    components: {
        ValidationProvider,
    },
    mixins: [ChampMixin],
})
export default class LabelField extends VueMixinsDecorator<ChampMixin>(ChampMixin) {
}
