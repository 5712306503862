


























































import Vue from 'vue';
import { Component, Prop, InjectReactive, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { buildRulesAsVeeValidateString } from '@/formsGenerator/components/Validations';
import { DateHelper, isNullOrEmpty } from '@/shared/helpers';
import { Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import ChampMixin from '@/formsGenerator/mixins/ChampMixin';
import moment from 'moment';

@Component({
    name: 'DatePickerField',
    components: {
        ValidationProvider,
    },
    mixins: [ChampMixin],
})
export default class DatePickerField extends VueMixinsDecorator<ChampMixin>(ChampMixin) {
    @Prop({ default: () => false }) public readonly isModeTemplate!: boolean;
    @Prop({ default: () => -1 }) public readonly sectionIndex!: number;
    @Prop({ default: () => -1 }) public readonly rowIndex!: number;
    @Prop({ default: () => ({}) }) public readonly formData!: object;
    @Prop({ default: '' }) public value!: any;
    @Prop({ default: () => ({}) }) public readonly busSharedData!: Vue;
    @InjectReactive('dateRai')
    private dateRai!: string;
    @InjectReactive('dateDebutEngagement')
    public dateDebutEngagement!: string;
    @InjectReactive('idOperation')
    public idOperation: number;

    public date: string = '';
    public menuWithoutButtons = false;

    public availableDates: string[] = new Array();

    public valueTextField: string = '';
    public valueDatePickerField: string = '';

    public lastPickerUpdateValue: string = null;

    public get rules(): any {
        return buildRulesAsVeeValidateString((this.currentField as any).validation, this.formData, null, null);
    }

    /**
     * Règle de validation du champs de texte.
     */
    public get dateTextFiedRules() {
        return [
            (v: string | any) => {
                return !v
                    || moment(v, DateHelper.formatDateFR).format(DateHelper.formatDateParDefaut) !== 'Invalid Date'
                    || this.allowedDates(moment(v, DateHelper.formatDateFR).format(DateHelper.formatDateParDefaut)) 
                    || 'Date invalide'
            },
        ];
    }

    /**
     * Refresh des dates disponibles pour le text field
     */
    @Watch('valueTextField')
    public refreshAvailableDatesFromText(): void {

        if (!!this.valueTextField && this.valueTextField.length === 10) {
            
            const datePickerValue = moment(this.valueTextField, DateHelper.formatDateFR).format('YYYY-MM');
            if (!!datePickerValue && datePickerValue !== 'Invalid Date') {
                this.pickerUpdate(datePickerValue);
            }
        }
    }

    /**
     * Refresh des dates disponibles
     */
    public refreshAvailableDates(): void {

        if (this.lastPickerUpdateValue && this.currentField) {
            this.pickerUpdate(this.lastPickerUpdateValue);
        }
    }

    /** 
     * Date du text field au format dd/mm/yyyy
     */
    public async refreshModel(notifyUpdate :boolean = false): Promise<void> {
        
        if (isNullOrEmpty(this.date)) {
            this.valueTextField = null;
            this.valueDatePickerField = null;
            await this.onInput(null, false, notifyUpdate);
            return;
        }

        let dateFormated: string;

        // Retourne le résultat.
        const isValid = moment(this.date, DateHelper.formatDateFR, true).isValid();
        if (isValid) {
            dateFormated = this.date;
        } else {
            dateFormated = DateHelper.format(this.date);
        }

        this.model = dateFormated;
        this.valueTextField = dateFormated;
        this.valueDatePickerField = moment(dateFormated, DateHelper.formatDateFR).format(DateHelper.formatDateParDefaut);

        await this.onInput(dateFormated, true, notifyUpdate);
    }

    public async input(datepickerValue: any): Promise<void> {
        
        this.menuWithoutButtons = false;
        this.date = datepickerValue;

        if (this.currentField.name === "DATE_DEBUT_TRAVAUX_SITE" && this.dateDebutEngagement) {                
            this.busSharedData.$emit('updateDateDebutTravauxSite', this.idOperation, this.date);             
        }

        await this.refreshModel();
    }

    public allowedDates(date: string): boolean {
        return this.availableDates.includes(date);
    }

    public async pickerUpdate(val: string) {
        this.lastPickerUpdateValue = val;
        let totalDay = moment(val, "YYYY-MM").daysInMonth();
        let monthNow = moment().format('M');
        let monthSelected = moment(val).format('M');
        let day: number = 1;
        let availableDates = [];

        for (let i: number = day; i <= totalDay; i++) {
            
            let date = moment(`${val}-${i}`);
            let dateStr = date.format(DateHelper.formatDateParDefaut);

            if(this.currentField) {
                if (this.currentField.name === "DATE_DEBUT_TRAVAUX_SITE" && this.dateDebutEngagement) {
                    let dateMinDebutTravaux = DateHelper.addDaysAndSkeepWeekend(this.dateDebutEngagement, 8);

                    if (date.day() !== DateHelper.samedi
                        && date.day() !== DateHelper.dimanche
                        && !DateHelper.joursFeries.some((item) => date.isSame(item))
                        && date.isSameOrAfter(dateMinDebutTravaux)
                    ) {
                        availableDates.push(dateStr);
                    }
                } else if(this.currentField.name === 'BONIFICATION_DATE_SIGNATURE') {
                    let dateMinBonification = this.dateRai ? this.dateRai : '2020-07-01';
                    let dateMaxBonification = this.dateDebutEngagement ? this.dateDebutEngagement : '9999-12-31';

                    if(date.isSameOrAfter(dateMinBonification) && date.isSameOrBefore(dateMaxBonification)) {
                        availableDates.push(dateStr);
                    }
                } else if (this.currentField.name === "DATE_VISITE" && this.dateDebutEngagement) {
                    let dateMaxVisite = moment(this.dateDebutEngagement);
                    
                    if (date.isSameOrBefore(dateMaxVisite)) {
                        availableDates.push(dateStr);
                    }
                } else {
                    availableDates.push(dateStr);
                }
            } else {
                availableDates.push(dateStr);
            }
        }
        this.availableDates = availableDates;
        this.allowedDates(val);
    }

    /**
     * Hook appelé quand le composant est monté.
     */
    public async mounted(): Promise<void> {
        this.date = this.value;
        this.refChampName = `ref_datepicker__${this.currentField.name}_${this.dataIndex}`;
        await this.refreshModel();

        this.busSharedData.$on('on-date-debut-travaux-updated', () => {
            if (this.currentField 
                && (this.currentField.name === "DATE_DEBUT_TRAVAUX_SITE" 
                    || this.currentField.name === "BONIFICATION_DATE_SIGNATURE"
                    || this.currentField.name === "DATE_VISITE")
                && this.date) {

                this.date = null;
                this.refreshModel(true);
            }
        });
    }
}
