














































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { ValidationProvider } from 'vee-validate';
import ChampMixin from '@/formsGenerator/mixins/ChampMixin';

@Component({
    name: 'CheckboxField',
    components: {
        ValidationProvider,
    },
    mixins: [ChampMixin],
})
export default class CheckboxField extends VueMixinsDecorator<ChampMixin>(ChampMixin) {

    @Prop({ default: () => ({}) }) public readonly busSharedData!: Vue;

    public previousValue: boolean = null;

    /**
     * Hook BeforeCreate.
     */
    public beforeCreate(): void {
        if (!this.model) {
            this.model = false;
        }
    }
    /**
     * Hook appelé quand le composant est monté.
     */
    public mounted(): void {
        this.refChampName = `ref_checkbox__${this.currentField.name}_${this.dataIndex}`;
        this.busSharedData.$on('toggle-CPE-disable', (value: boolean) => {
            if (this.currentField.name === 'BONIFICATION_CPE') {
                this.currentField.disabled = value;
                this.onChange(false);
                this.model = false;
            }
        });
    }

    /**
     * Event lors d'une modification de la valeur.
     */
    public onChange(value: boolean): void {

        // Hack pour éviter de déclencher l'évènement à l'initialisation
        // car le @change est déclenché automatiquement par le mandatory du button...
        this.onInput(value, true, this.previousValue !== null);
        this.previousValue = value;
    }
}
