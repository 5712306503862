




























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { components } from '../components/Fields';
import { estVisible, possedeConditionDeVisibilite } from '@/formsGenerator/components/Visibilite';
import { SurfacesControle } from '@/shared/enums';
import { debounce } from 'lodash-es';

@Component({
    name: 'SectionComponentRowItem',
    components: {
        ...components,
    },
})
export default class SectionComponentRowItem extends Vue {
    @Prop({ default: () => ({ schema: {} }) }) public readonly form!: any;
    @Prop({ default: () => ({}) }) public readonly formConfig!: any;
    @Prop({ default: () => ({}) }) public readonly formData!: object | { [key: string]: any };
    @Prop({ default: () => -1 }) public readonly sectionIndex!: number;
    @Prop({ default: () => -1 }) public readonly rowParentIndex!: number;
    @Prop({ default: () => -1 }) public readonly rowIndex!: number | null;
    @Prop({ default: () => '' }) public readonly parentFieldName!: string;
    @Prop({ default: () => (null as unknown as number) }) public readonly positionIndexArray!: number | null;
    @Prop({ default: () => (null as unknown as string) }) public readonly dataIndex!: string | null;
    @Prop({ default: () => false }) public readonly isModeTemplate!: boolean;
    @Prop({ default: () => ('') }) public readonly buildedFieldName!: string;
    /**
     * Bus individuel de données pour chaque opération.
     */
    @Prop({ default: () => ({}) }) public readonly bus!: Vue;
    /**
     * Bus pour partager les données entre opérations.
     */
    @Prop({ default: () => new Vue() }) public readonly busSharedData!: Vue;
    /**
     * Liste des champs qui partagent les données entre Opérations.
     */
    @Prop({ default: () => new Array<string>() }) public readonly fieldsNameSharedData!: string[];
    /**
     * Est ce que cet item est visible ou pas.
     */
    public get estVisible(): boolean {
        const data = (this.formData as { [key: string]: any })[this.dataIndex];
        const value = data ? data.value : null;
        
        const { field, ...item } = this.contruireItemCourant(value);
        return estVisible(this.dataIndex, this.form, this.formData, item, null);
    }

    /**
     * Est ce que cet item possède une condition de visibilité.
     */
    public possedeConditionDeVisibilite(): boolean {
        return possedeConditionDeVisibilite(this.form);
    }

    public updateRowItemData(value: any, notifyUpdate: boolean): void {
        const item = this.contruireItemCourant(value);           
        if (Object.values(SurfacesControle).includes(item.fieldName)) {
            notifyUpdate = true;
        }
        // Envoie de l’événement.
        this.onUpdateFormData(item, notifyUpdate);
    }
    /**
     *  DEBOUNCE Événement pour mise à jour du formulaire.
     */
    public debounceOnUpdateFormData = debounce(this.onUpdateFormData, 0, {});
    /**
     * Événement pour mise à jour du formulaire.
     */
    public onUpdateFormData(item: any, notifyUpdate: boolean) {
        if (item && Object.keys(item).length >= 1) {
            // On vérifie si ce sont des champs qui peuvent partager les mises à jour.
            if (this.fieldsNameSharedData
                && this.fieldsNameSharedData.length >= 1
                && this.fieldsNameSharedData.includes(item.fieldName)) {
                // SI OUI.
                this.busSharedData.$emit('updateFormData', item, notifyUpdate);
            } else {
                // SI NON.
                this.bus.$emit('updateFormData', item, notifyUpdate);
            }
        }
    }

    public updateOnePropertyValueFormData(elt: { dataIndex: string, value: string | number | boolean | null }, notify: boolean = false) {
        const item = this.contruireItemCourant(elt.value);
        this.bus.$emit('updateFormData', item, notify);
        //this.bus.$emit('updateOnePropertyValueFormData', elt);
    }

    /**
     * Construit l'élément ou la ligne de data courante, correspondant à un champ du formulaire.
     * @param value La valeur saisie
     */
    public contruireItemCourant(value: any): { [key: string]: any } {
        const item = {
            dataIndex: this.dataIndex,
            parentFieldName: this.parentFieldName,
            fieldName: this.form.name,
            sectionIndex: this.sectionIndex,
            rowParentIndex: this.rowParentIndex,
            rowIndex: this.rowIndex,
            positionIndexArray: this.positionIndexArray,
            childrenIndex: this.positionIndexArray,
            field: this.form,
            value,
        } as { [key: string]: any };
        return item;
    }
}
