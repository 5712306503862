
import TextInputField from './_base/TextInputField.vue';
import TextEditorField from './_base/TextEditorField.vue';
import NumberInputField from './_base/NumberInputField.vue';
import DecimalInputField from './_base/DecimalInputField.vue';
import CheckBoxField from './_base/CheckBoxField.vue';
import RadioButtonField from './_base/RadioButtonField.vue';
import SelectListField from './_base/SelectListField.vue';
import DatePickerField from './_base/DatePickerField.vue';
import TimePickerField from './_base/TimePickerField.vue';
import LabelField from './_base/LabelField.vue';

import ArrayField from './advanced/ArrayField.vue';

import { control } from './Constants';

export enum TypeDeChamp {
    TextInputField = 'TextInputField',
    TextEditorField = 'TextEditorField',
    NumberInputField = 'NumberInputField',
    DecimalInputField = 'DecimalInputField',
    CheckBoxField = 'CheckBoxField',
    RadioButtonField = 'RadioButtonField',
    SelectListField = 'SelectListField',
    DatePickerField = 'DatePickerField',
    TimePickerField = 'TimePickerField',
    LabelField = 'LabelField',
    // Avancés
    ArrayField = 'ArrayField',
}

export const fields = {
    base: [
        {
            fieldType: TypeDeChamp.TextInputField,
            fieldLibelle: TypeDeChamp.TextInputField,
            component: TextInputField,
            ...{ ...{ htmlType: 'text' }, ...control },
        },
        {
            fieldType: TypeDeChamp.TextEditorField,
            fieldLibelle: TypeDeChamp.TextEditorField,
            component: TextEditorField,
            ...{ ...{ htmlType: 'textarea' }, ...control },
        },
        {
            fieldType: TypeDeChamp.NumberInputField,
            fieldLibelle: TypeDeChamp.NumberInputField,
            component: NumberInputField,
            ...{ ...{ htmlType: 'number' }, ...control },
        },
        {
            fieldType: TypeDeChamp.DecimalInputField,
            fieldLibelle: TypeDeChamp.DecimalInputField,
            component: DecimalInputField,
            ...{ ...{ htmlType: 'number' }, ...control },
        },
        {
            fieldType: TypeDeChamp.CheckBoxField,
            fieldLibelle: TypeDeChamp.CheckBoxField,
            component: CheckBoxField,
            ...{ ...{ htmlType: 'checkbox' }, ...control },
        },
        {
            fieldType: TypeDeChamp.RadioButtonField,
            fieldLibelle: TypeDeChamp.RadioButtonField,
            component: CheckBoxField,
            ...{ ...{ htmlType: 'radio' }, ...control },
        },
        {
            fieldType: TypeDeChamp.SelectListField,
            fieldLibelle: TypeDeChamp.SelectListField,
            component: SelectListField,
            ...{ ...{ htmlType: 'select' }, ...control },
        },
        {
            fieldType: TypeDeChamp.DatePickerField,
            fieldLibelle: TypeDeChamp.DatePickerField,
            component: DatePickerField,
            ...{ ...{ htmlType: 'select' }, ...control },
        },
        {
            fieldType: TypeDeChamp.TimePickerField,
            fieldLibelle: TypeDeChamp.TimePickerField,
            component: TimePickerField,
            ...{ ...{ htmlType: 'select' }, ...control },
        },
        {
            fieldType: TypeDeChamp.LabelField,
            fieldLibelle: TypeDeChamp.LabelField,
            component: LabelField,
            ...{ ...{ htmlType: 'label' }, ...control },
        },
    ],
    advanced: [
        {
            fieldType: TypeDeChamp.ArrayField,
            fieldLibelle: TypeDeChamp.ArrayField,
            component: ArrayField,
            ...{ ...{ htmlType: 'array' }, ...control },
        },
    ],
};

export const components = {
    TextEditorField,
    TextInputField,
    NumberInputField,
    DecimalInputField,
    CheckBoxField,
    RadioButtonField,
    SelectListField,
    DatePickerField,
    TimePickerField,
    LabelField,

    ArrayField,
};

export const estChampDeTypeNumerique = (champ: any) => {
    if (champ != null && typeof champ !== 'undefined') {
        const typeChampNumerique = [TypeDeChamp.NumberInputField, TypeDeChamp.DecimalInputField];
        return champ.isInteger || Object.values(typeChampNumerique).includes(champ.fieldType);
    }
    return false;
};

export const estChampDeTypeTexte = (champ: any) => {
    if (champ != null && typeof champ !== 'undefined') {
        const typeChampTexte = [TypeDeChamp.TextInputField, TypeDeChamp.TextEditorField];
        return Object.values(typeChampTexte).includes(champ.fieldType);
    }
    return false;
};

export const estChampDeTypeMutiple = (champ: any) => {
    if (champ != null && typeof champ !== 'undefined') {
        const typeChampMutltiple = [TypeDeChamp.SelectListField, TypeDeChamp.RadioButtonField];
        return Object.values(typeChampMutltiple).includes(champ.fieldType);
    }
    return false;
};

