var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isArrayField())?_c('div',{class:_vm.getClassRowItem(_vm.form),on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.configureActiveField(_vm.form)}}},[_c('div',[(_vm.isModeTemplate)?_c('span',[(_vm.form.hasOwnProperty('name') && (_vm.form.name!==null && _vm.form.name !==''))?_c('span',{staticClass:"form__selectedlabel"},[_vm._v(" "+_vm._s(_vm.form.name)+" ("+_vm._s(_vm.form.fieldType)+") "+_vm._s(_vm.possedeConditionDeVisibilite() ? '- Possède une condition de visibilité':'')+" ")]):_c('span',{staticClass:"form__selectedlabel"},[_vm._v(_vm._s(_vm.form.fieldType))])]):_vm._e(),(_vm.form.children && _vm.form.children.length)?_c('div',{attrs:{"id":_vm.isModeTemplate ? 'rowTemplate' : ''},on:{"mouseover":_vm.onMouseOverChild,"mouseleave":_vm.onMouseLeaveChild}},[_c('draggable',{attrs:{"list":_vm.form.children,"disabled":!_vm.isModeTemplate}},_vm._l((_vm.form.children),function(child,childRowIndex){return _c('div',{key:_vm.buildDataIndex(childRowIndex),class:_vm.getClassRowItem(child),on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.configureActiveField(child)}}},[_c('SectionComponentRowItem',{attrs:{"form":child,"form-config":_vm.formConfig,"form-data":_vm.formData,"bus":_vm.bus,"bus-shared-data":_vm.busSharedData,"fields-name-shared-data":_vm.fieldsNameSharedData,"section-index":_vm.sectionIndex,"row-parent-index":_vm.rowParentIndex,"row-index":_vm.rowIndex,"data-index":_vm.buildDataIndex(childRowIndex),"position-index-array":childRowIndex,"is-mode-template":_vm.isModeTemplate,"parent-field-name":_vm.form.name,"builded-field-name":_vm.buildFieldName(_vm.sectionIndex, _vm.rowIndex, childRowIndex, childRowIndex, child.name)},on:{"cloneElement":_vm.cloneElement,"deleteElement":function($event){return _vm.deleteElement({sectionIndex: _vm.sectionIndex,
                                             rowParentIndex: _vm.rowParentIndex,
                                             rowIndex: childRowIndex,
                                             positionIndexArray: childRowIndex,
                                             field: _vm.form
                                            })}}})],1)}),0)],1):_vm._e(),(_vm.isModeTemplate && _vm.isArrayField())?_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openDialogChoosenFields()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" add ")])],1),_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"primary","icon":""},on:{"click":function($event){return _vm.deleteElement({sectionIndex: _vm.sectionIndex, rowParentIndex: _vm.rowParentIndex, rowIndex: _vm.rowIndex, field: _vm.form})}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" delete ")])],1)],1):(!_vm.isModeTemplate && _vm.isArrayField())?_c('div',{staticClass:"text-left text-md-left text-lg-left form__actiongroup"},[(_vm.isLastElement)?_c('v-btn',{staticClass:"text-left text-md-left text-lg-left",attrs:{"text":"","fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.cloneDataRenderElement({
                    estAsupprimer:false,
                    fieldName: _vm.form.name,
                    parentFieldName: _vm.form.name,
                    sectionIndex: _vm.sectionIndex, rowParentIndex: _vm.rowParentIndex,
                    rowIndex: _vm.rowParentIndex,
                    nombreOccurence: _vm.nombreOccurence,
                    field: _vm.form
                  })}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" add ")])],1):_vm._e(),((_vm.rowIndex > 0) && _vm.isLastElement)?_c('v-btn',{staticClass:"text-left text-md-left text-lg-left",attrs:{"text":"","fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.deleteDataRenderElement({
                    estAsupprimer:true,
                    fieldName: _vm.form.name,
                    parentFieldName: _vm.form.name,
                    sectionIndex: _vm.sectionIndex, rowParentIndex: _vm.rowParentIndex,
                    rowIndex: _vm.rowParentIndex,
                    nombreOccurence: _vm.nombreOccurence,
                    field: _vm.form
                  })}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" delete ")])],1):_vm._e()],1):_vm._e()])]):_c('div',{class:_vm.getClassRowItem(_vm.form),on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.configureActiveField(_vm.form)}}},[_c('SectionComponentRowItem',{key:'rowitem_sectionIndex_'+_vm.buildDataIndex(null),attrs:{"form":_vm.form,"form-config":_vm.formConfig,"form-data":_vm.formData,"bus":_vm.bus,"bus-shared-data":_vm.busSharedData,"fields-name-shared-data":_vm.fieldsNameSharedData,"section-index":_vm.sectionIndex,"row-parent-index":_vm.rowParentIndex,"row-index":_vm.rowIndex,"data-index":_vm.buildDataIndex(null),"position-index-array":null,"is-mode-template":_vm.isModeTemplate,"parent-field-name":_vm.form.name,"builded-field-name":_vm.buildFieldName(_vm.sectionIndex, _vm.rowIndex, null, null, null)},on:{"editActiveFieldEvent":_vm.editActiveField,"cloneElement":_vm.cloneElement,"deleteElement":function($event){return _vm.deleteElement({sectionIndex: _vm.sectionIndex, rowParentIndex: _vm.rowParentIndex, rowIndex: _vm.rowIndex, positionIndexArray:-1, field: _vm.form})}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }