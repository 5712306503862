import {
    isBoolean,
    isString
} from 'lodash';
import jsonLogic from 'json-logic-js';
const logger = console;

// Configure JsonLogic.
// lodashOperators.forEach((name) => jsonLogic.add_operation(`_${name}`, (_ as any)[name]));

const lodashFunction = {
    isBoolean,
    isString
};

export { jsonLogic/*, moment*/ };

/**
 * Get a property value of an element.
 *
 * @param style
 * @param prop
 * @return {number}
 */
export function getPropertyValue(style: any, prop: any) {
    let value = style.getPropertyValue(prop);
    value = value ? value.replace(/[^0-9.]/g, '') : '0';
    return parseFloat(value);
}

/**
 * Determines the boolean value of a setting.
 *
 * @param value
 * @return {boolean}
 */
export function boolValue(value: any) {
    if (isBoolean(value)) {
        return value;
    } else if (isString(value)) {
        return (value.toLowerCase() === 'true');
    } else {
        return !!value;
    }
}

/**
 * Fonction qui utilise Jsonlogic pour faire des validations métiers et aussi de visibilité.
 * @param fieldName
 * @param json
 * @param data
 * @param dataGroupbyFieldName
 * @param row
 * @param form
 * @param onError
 */
export function checkJsonConditional(fieldName: string, json: any, data: any, dataGroupbyFieldName: any, row: any, form: any, onError: any) {
    try {
        /* Region : Au chargement d'une opération, il faut cast la valeur en nombre pour résoudre le condition correctement si l'opérateur contient un chevron. */
        const operators = Object.keys(json);
        operators.forEach((operator: string) => {
            if (operator.includes(">=") || operator.includes("<=")) {
                const fieldIndex = json[operator][0]['var'].split('.')[1];
                data[fieldIndex].value = parseInt(data[fieldIndex].value);
            }
        });
        /* endRegion */
        const result = jsonLogic.apply(json, {
            data,
            dataGroupbyFieldName,
            row,
            form,
            lodashFunction,
        });
        if (typeof result === 'string') {
            return JSON.parse(result);
        }
        return result;
    } catch (err) {
        // tslint:disable-next-line:no-console
        logger.warn(`An error occurred in jsonLogic advanced condition for ${fieldName}`, err);
        return onError;
    }
}


/**
 * Test a trigger on a component.
 *
 * @param component
 * @param action
 * @param data
 * @param dataGroupbyFieldName
 * @param row
 * @returns {mixed}
 */
export function checkTrigger(trigger: any, fieldName: string, json: string, data: any, dataGroupbyFieldName: any, row: any, form: any, onError: any) {
    
    if (trigger.type === 'json') {
        return checkJsonConditional(fieldName, json, data, dataGroupbyFieldName, row, form, onError);
    } else {
        throw new Error(`${trigger.type} Not found`);
    }
}



export const isNullOrUndefined = (value: any): boolean => (typeof value === 'undefined' || value === null);
export const isNullOrEmpty = (s: string) => isNullOrUndefined(s) || (s == null || s === '' || s === ' ');

/**
 * Groupe un tableau par la clef spécifié.
 * @param items
 * @param key
 */
export const groupBy = (items: any, key: string) => items.reduce(
    (result: any, item: any) => ({
        ...result,
        [item[key]]: [
            ...(result[item[key]] || []),
            item,
        ],
    }),
    {},
);

export const isDecimal = (value: any) => {
    return /^-?(?:0|0\.\d*|[1-9]\d*\.?\d*)$/.test(value);
};

export const unmask = (value: any, ds = ',') => {
    return value.replace(ds, '.');
};

export const mask = (value: any, dp = -1, editing = false, ds = ',', gs = '.') => {
    if (editing || !isDecimal(value)) {
        return value.replace('.', ds);
    }

    const parts = value.split(/\./);

    let dec = parts[1] || '';

    if (dp >= 0) {
        dec = dec.length < dp ? dec.padEnd(dp, '0') : dec.substr(0, dp);
    }

    if (dec) {
        dec = ds + dec;
    }

    return parts[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1' + gs) + dec;
};

/**
 * https://stackoverflow.com/questions/10865025/merge-flatten-an-array-of-arrays
 * @param arr
 */
export const flatten = (arr: any[]): any[] => {
    return arr.reduce((flat, toFlatten) => {
        return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
};

/**
 * Construit l'inde ou position d'un champ en fonction de ses paramètres.
 * @param sectionIndex
 * @param rowParentIndex
 * @param rowIndex
 * @param positionIndexArray
 */
export const buildFinalFieldIndex = (sectionIndex: number, rowParentIndex: number, rowIndex: number, positionIndexArray: number | null): string => {
    const listIndex = [sectionIndex, rowParentIndex, rowIndex];
    if (positionIndexArray !== null && positionIndexArray >= 0) {
        listIndex.push(positionIndexArray);
    }
    return `${listIndex.join('_')}`;
};
