

















































import { Action } from 'vuex-class';
import { ReferentielStoreMethods } from '@/store/modules/referentiel/ReferentielStore';
import { ValeurReferentielle } from '@/shared/models';

import { Component, Watch } from 'vue-property-decorator';
import { Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { ValidationProvider } from 'vee-validate';
import ChampMixin from '@/formsGenerator/mixins/ChampMixin';

@Component({
    name: 'SelectListField',
    components: {
        ValidationProvider,
    },
    mixins: [ChampMixin],
})
export default class SelectListField extends VueMixinsDecorator<ChampMixin>(ChampMixin) {
    public loading = false;
    public dataOptions: ValeurReferentielle[] = [];

    // Méthode de récupération des données de la liste.
    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES_URI_PATH)
    public getDataOptions: (uriPath: string) => Promise<ValeurReferentielle[]>

    @Watch('currentField', { immediate: true, deep: true })
    public onIsAjaxChanged() {
        this.dataOptions = [];
        this.loadDataOptions();
    }

    // Affiche le libellé sélectionné.
    public get selectedTextLibelle(): string | undefined {
        const selectObject = this.dataOptions.find((item: { id: number }) => item.id === parseInt(this.model.toString(), 10)) as { libelle: string };
        if (selectObject) {
            return selectObject.libelle;
        }
        return this.currentField.placeholder;
    }

    public loadDataOptions(): void {
        if (this.currentField.isAjax && this.urlSelect !== null) {
            this.loading = true;

            this.getDataOptions(this.urlSelect)
                .then((result: ValeurReferentielle[]) => this.dataOptions = result)
                .finally(() => this.loading = false);

        } else if (this.currentField.dataOptions) {
            this.dataOptions = this.currentField.dataOptions;
        }
    }

    private get urlSelect(): string | null {
        const item = this.formConfig.listeEnumReferentiel.find((elt: any) => elt.id === this.currentField.ajaxDataUrl);
        if (item) {
            return item.code
                .replace(/codeOperation/g, this.formConfig.templateOperationCode)
                .replace(/arreteId/g, this.formConfig.arreteId);
        }
        return null;
    }

    /**
     * Hook appelé quand le composant est monté.
     */
    public mounted(): void {
        this.refChampName = `ref_select__${this.currentField.name}_${this.dataIndex}`;
        // Si aucune valeur n'a été sélectionnée, la valeur par défaut est celle du 1er item des dataOptions.
        if (!this.valeurDuChamp) {
            // On fait une notification aux parents.
            if (!!this.currentField.data.defaultValue && parseInt(this.currentField.data.defaultValue, 10) > 0) {
                this.$nextTick(() => {
                    //this.updateValue(this.currentField.data.defaultValue);
                    this.$emit('updateOnePropertyValueFormData', { dataIndex: this.dataIndex, value: this.currentField.data.defaultValue });
                });
            } else {
                this.$nextTick(() => {
                    //this.updateValue(1);
                    
                    this.model = 1;
                    this.$emit('updateOnePropertyValueFormData', { dataIndex: this.dataIndex, value: 1 });
                });
            }
        }
        if (!this.dataOptions || this.dataOptions.length <= 0) {
            return;
        }
        if (this.dataOptions) {
            this.loadDataOptions();
        }
    }

    public input(value: any): void {
        if (value !== this.valeurDuChamp) {
            this.$emit('updateOnePropertyValueFormData', { dataIndex: this.dataIndex, value: value }, true);
        }
        this.$emit('onInput', value);
    }
}
