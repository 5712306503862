import { render, staticRenderFns } from "./SectionComponent.vue?vue&type=template&id=308e7f0f&scoped=true&"
import script from "./SectionComponent.vue?vue&type=script&lang=ts&"
export * from "./SectionComponent.vue?vue&type=script&lang=ts&"
import style0 from "./SectionComponent.scss?vue&type=style&index=0&id=308e7f0f&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "308e7f0f",
  null
  
)

export default component.exports