import { Vue, Prop, Watch } from 'vue-property-decorator';
import { Mixin as VueMixinDecorator } from 'vue-mixin-decorator';
import { buildRulesAsVeeValidateString } from '@/formsGenerator/components/Validations';
import { catalogueFonctions } from '@/formsGenerator/components/CatalogueFonctions';
import { FormulaireDataItem } from '@/formsGenerator/interfaces';
import { isNullOrUndefined, isNullOrEmpty, DateHelper } from '@/shared/helpers';
import moment from 'moment';
import { isBoolean, debounce } from 'lodash-es';

/**
 * Mix in regroupant les différentes méthodes de utilisées pour la gestion des champs de façon dynamiques.
 */
@VueMixinDecorator
export default class ChampMixin extends Vue {
    @Prop({ default: () => false }) public readonly isModeTemplate!: boolean;
    @Prop({ default: () => ({}) }) public readonly currentField!: any;
    @Prop({ default: () => -1 }) public readonly sectionIndex!: number;
    @Prop({ default: () => -1 }) public readonly rowParentIndex!: number;
    @Prop({ default: () => -1 }) public readonly rowIndex!: number;
    @Prop({ default: () => (null as unknown as (number | null)) }) public readonly positionIndexArray!: number | null;
    @Prop({ default: () => (null as unknown as string) }) public readonly dataIndex!: string;
    @Prop({ default: () => '' }) public readonly parentFieldName!: string;
    @Prop({ default: () => '' }) public readonly fieldName!: string;
    @Prop({ default: () => ({}) }) public readonly formConfig!: any;
    @Prop({ default: () => ({}) }) public readonly formData!: { [key: string]: any };
    @Prop({ default: '' }) public value!: string;

    // Model du champ.
    public model: string | number | boolean | null = null;

    // Référence du champ.
    public refChampName: string | null = null;

    public get estVisible(): boolean {
        return true;
    }

    public get valeurDuChamp(): string | number | null {
        try {
            // Récupère le résultat.
            const value = this.deduitValeurDuChampCalculeEtEmetResultat() || this.value || this.currentField.data.defaultValue;
            if (value === null || typeof value === (void (0)) || isNullOrUndefined(value) || isNullOrEmpty(value)) {
                return null;
            } else {
                // S'il s'agit d'une date, vérifie qu'on n'est pas au format français.
                if (moment(value, DateHelper.formatDateFR, true).isValid()) {
                    return moment(value, DateHelper.formatDateFR).format(DateHelper.formatDateParDefaut);
                }
                return value;
            }

        } catch {
            return this.value || this.currentField.data.defaultValue || null;
        }
    }

    /**
     * Valeur du champ calculé, si une formule de calcul existe.
     */
    public deduitValeurDuChampCalculeEtEmetResultat(): string | number | null {
        if (this.currentField && this.currentField.data && this.currentField.data.customValue) {
            try {
                // Récupère nom de la fonction.
                const fnString = this.currentField.data.customValue;

                // Construction des arguments de la fonction.
                const datas: { [key: string]: FormulaireDataItem } = this.formData as ({ [key: string]: FormulaireDataItem });
                const args: any[] = [datas, datas, {
                    index: this.dataIndex,
                    parentFieldName: this.parentFieldName,
                    fieldName: this.fieldName,
                    sectionIndex: this.sectionIndex,
                    rowParentIndex: this.rowParentIndex,
                    rowIndex: this.rowIndex,
                    positionIndexArray: this.positionIndexArray,
                    value: datas[this.dataIndex].value,
                } as FormulaireDataItem];

                // Cherche la fonction, si la trouve , l'exécute en passant les arguments.
                const fn = catalogueFonctions[fnString];
                if (typeof fn === 'function') {
                    const resultat = fn.apply(null, args);
                    // On émet les résultats du calcul pour notifier les autres composants.
                    if (resultat) {
                        datas[this.dataIndex].value = resultat;
                    }
                    // Retourne résultat.
                    return resultat;
                }

            } catch {
                return null;
            }
        }
        return null;
    }

    public get rules(): any {
        return buildRulesAsVeeValidateString(this.currentField.validation, this.formData, null, null);
    }

    public updateValue(value: any, notifyUpdate: boolean = true): void {
        this.$emit('input', value, notifyUpdate);
    }

    public onBlur(): void {
        if (this.getValidator(this.currentField.name)) {
            this.getValidator(this.currentField.name).setFlags({
                untouched: false,
                touched: true,
            });
        }
    }

    public async onInput(value: any, doValidation: boolean = true, notifyUpdate: boolean = true): Promise<any> {
        const validator: any = this.getValidator(this.refChampName || this.currentField.name);

        if (!!validator) {
            // We could forcibly set the value to undefined.
            validator.syncValue(value);
            // Validate the newly set value
            if (doValidation) {
                await validator.validate();
            }

            validator.setFlags({
                dirty: true,
                pristine: false,
            });
            this.updateValue(value || validator.value, notifyUpdate);
        }
        else if (value && value !== null && typeof value !== 'undefined') {
            this.updateValue(value || validator.value, notifyUpdate);
        }
    }

    public getValidator(fieldName: string): any {
        const refValidatorName = fieldName;
        if (!!refValidatorName && this.$refs[refValidatorName]) {
            return this.$refs[refValidatorName];
        }
        return null;
    }

    /**
     * Hook appelé quand le composant est monté.
     */
    public mounted(): void {
        this.majDuModele(this.valeurDuChamp);
        if (this.valeurDuChamp) {
            // On fait une notification aux parents.
            this.updateValue(this.valeurDuChamp, false);
        }
    }
    // Vérifie si la valeur du champ change.
    @Watch('valeurDuChamp', { immediate: false })
    public onValeurDuChampChanged(val: string | null) {
        this.majDuModele(val);
    }
    /**
     * DEBOUNCE sur la Mise à jour du modèle..
     */
    public debounceMajDuModele = debounce(this.majDuModele, 0, {});
    /**
     * Mise à jour du modèle.
     */
    public majDuModele(val: string | number | boolean | null) {

        // Vérifie si val est bien renseignée.
        if (!isNullOrEmpty(val)) {
            // Vérifie si c'est un booléen.
            if (isBoolean(val)) {
                this.model = this.strToBool(val.toString());
            } else {
                // Affecte le model, en le convertissant en string.
                this.model = val.toString();
            }
        }
        // On fait une notification aux parents.
        //this.updateValue(this.model);
    }
    /*
     * Converts a string to a booléen.
     * https://stackoverflow.com/questions/263965/how-can-i-convert-a-string-to-boolean-in-javascript
     * This conversion will:
     *
     *  - match 'true', 'on', or '1' as true.
     *  - ignore all white-space padding
     *  - ignore capitalization (case).
     *
     * '  tRue  ','ON', and '1   ' will all evaluate as true.
     *
     */
    private strToBool(s: string): boolean {
        // will match one and only one of the string 'true','1', or 'on' rerardless
        // of capitalization and regardless off surrounding white-space.
        //
        const regex = /^\s*(true|1|on)\s*$/i

        return regex.test(s);
    }
}
