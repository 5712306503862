
const FORM_CONSTANTS = {
    OptionDefault : {
        id: '',
        code: '',
        libelle: '',
    },
};
const CONTROL_CONSTANTS = {};

const sectionLayout: any = {
    collapse: 'Collapse',
    tab: 'Tab',
};

const section: any = {
    name: '',
    label: '',
    clientKey: '',
    order: 0,
    rows: [],

    // Config
    labelPosition: 'left', // Left or top

    // For dynamic
    isDynamic: false,
    minInstance: 1,
    maxInstance: 0,
    instances: [], // For save data in GUI to easily to retrieve @@
};

const row: any = {
    name: '',
    label: '',
    order: 0,
    controls: [],
};

export const control: any = {
    // Common.
    id: 0,
    parentId: null,
    templateOperationId: 0,
    templateBlocId: 0,
    templateTypeBlocId: 0,
    templateBlocChampId: 0,
    name: '',
    htmlType: '',
    estSelectionne: false,

    // Label style
    label: '',
    labelBold: false,
    labelItalic: false,
    labelUnderline: false,
    errorLabel: '',
    //
    value: '',
    //
    placeholder: '',
    description: '',
    helpText: '',
    //
    tooltip: '',
    prefix: '',
    suffix: '',
    customCssclass: '',
    tabIndex: 0,
    order: 0,
    className: '',
    disabled: false,
    readonly: false,
    clearable: false,
    browserAutocomplete: false,

    // Attr for text
    isMultiLine: false,

    // Attr for number
    isInteger: false,
    decimalPlace: 0,
    // Attr for datePicker
    isTodayValue: false,
    dateFormat: 'dd/mm/yy',

    // Attr for timePicker
    isNowTimeValue: false,
    timeFormat: 'HH:mm', // Enhancement later

    // Attr for select
    isMultiple: false,
    isAjax: true, // Is ajax data source or not
    dataOptions: [], // Static data source
    ajaxDataUrl: '', // Ajax data source

    // Attr for checkbox
    isChecked: false,
    showWordCount: false,
    showCharCount: false,
    // Data.
    data: {
        // Values
        defaultValue: '',
        value: '',
        refreshOn: '',
        inputMask: '',
        inputFormat: '',
        useDelimeter: '',
        decimalPlace: '',
        minimumValue: 0,
        maximumValue: 9999,
        customValue: null,
    },
    // Validation.
    validation: {
        conditionalValidation: '',
        required: false,
        isUnique: false,
        minimumLength: 0,
        maximumLength: 9999,
        minimumValue: null,
        maximumValue: null,
        minimumWorld: 0,
        maximumWorld: 9999,
        regularExpressionPattern: '',
        veeValidate: {},
        customValidation: '',
        validateOn: 'Change',
        customerrorMessage: '',
    },
    // Conditional.
    conditionalVisibility: '',
    // Logic.
    logic: '',
    // Styles.
    styles: '',
    properties: {},
    children: [],
};

const dateFormat: any = {
    // Rule: date picker format => moment format
    'dd/mm/yy': 'D/M/YYYY',
    'dd-mm-yy': 'D-M-YYYY',
    'mm/dd/yy': 'M/D/YYYY',
    'mm-dd-yy': 'M/D/YYYY',
    'yy/mm/dd': 'YYYY/M/D',
    'yy-mm-dd': 'YYYY-M-D',
};

const timeFormat: any = {
    'H:m': 'H:m',
    'HH:mm': 'HH:mm',
    'h:m p': 'h:m A',
    'hh:mm p': 'hh:mm A',
};

export {
    FORM_CONSTANTS,
    CONTROL_CONSTANTS,
};
