



















































import { Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { buildRulesAsVeeValidateString } from '@/formsGenerator/components/Validations';
import { Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import ChampMixin from '@/formsGenerator/mixins/ChampMixin';
@Component({
    name: 'TimePickerField',
    components: {
        ValidationProvider,
    },
    mixins: [ChampMixin],
})
export default class TimePickerField extends VueMixinsDecorator<ChampMixin>(ChampMixin) {
    @Prop({ default: () => false }) public readonly isModeTemplate!: boolean;
    @Prop({ default: () => -1 }) public readonly sectionIndex!: number;
    @Prop({ default: () => -1 }) public readonly rowIndex!: number;
    @Prop({ default: () => ({}) }) public readonly formData!: object;
    @Prop({ default: '' }) public value!: any;

    public time = '';
    public menuWithoutButtons = false;
    public get rules(): any {
        return buildRulesAsVeeValidateString((this.currentField as any).validation, this.formData, null, null);
    }

    public get computedTimeFormatted(): any {
        if (!this.time) {
            return null;
        }
        return this.time;
    }

    public input(timePickerValue: any): void {
        this.menuWithoutButtons = false;
        this.time = timePickerValue;
        this.$emit('input', timePickerValue);
    }
    /**
     * Hook appelé quand le composant est monté.
     */
    public mounted(): void {
        this.refChampName = `ref_input_text__${this.currentField.name}_${this.dataIndex}`;
        this.time = this.value || '';
    }
}
