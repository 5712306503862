var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.validationsFormConfig.some(function (item) { return item.hasError; }))?_c('v-row',{staticClass:"fluid fill-height",attrs:{"justify":"center"}},[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-text',[_c('div',{staticClass:"text-center align-center"},[_c('p',[_vm._v("Des erreurs dans la configuration du template de votre formulaire sont détectés, veuillez les corriger.")]),_vm._l((_vm.validationsFormConfig),function(item,index){return (item.hasError)?_c('v-alert',{key:index,attrs:{"value":item.message,"type":"error"}},[_vm._v(" "+_vm._s(item.message)+" ")]):_vm._e()})],2)])],1)],1):_c('v-row',{staticClass:"fluid fill-height"},[_c('v-col',[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var reset = ref.reset;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
var validate = ref.validate;
var errors = ref.errors;
return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();validate().then(_vm.submit)}}},[_c('v-card',{staticClass:"elevation-0"},_vm._l((_vm.formConfig.sections),function(section,sectionIndex){return (_vm.afficherSection(section))?_c('v-card-text',{key:section.name+'__'+sectionIndex,attrs:{"id":section.name}},[_c('h3',{domProps:{"innerHTML":_vm._s(section.libelle)}},[_vm._v(_vm._s(section.libelle))]),_vm._l((section.schemas),function(field,rowIndex){return (_vm.estVisible(field))?_c('v-card-text',{key:section.name+'__'+sectionIndex+'__'+rowIndex},[_c('SectionComponentRowPreview',{key:'SectionComponentRowPreview__'+section.name+'__'+sectionIndex+'__'+rowIndex,attrs:{"champ-preview-config":_vm.construitChampPreviewConfig(field, {
                                                                  sectionIndex: sectionIndex,
                                                                  rowParentIndex: rowIndex,
                                                                  errorMessages: errors,
                                                                  success: valid }),"field":field}})],1):_vm._e()}),_c('v-container',_vm._l((section.schemas),function(field,rowIndex){return (_vm.estVisible(field) && field.description)?_c('v-row',{key:section.name+'__'+sectionIndex+'__'+rowIndex+'__form-description',staticClass:"fill-height",attrs:{"align":"center","justify":"start"}},[_c('small',{domProps:{"innerHTML":_vm._s(field.description)}})]):_vm._e()}),1)],2):_vm._e()}),1),(!!_vm.$slots['FormBuilderPreviewFooter'] || !!_vm.$scopedSlots['FormBuilderPreviewFooter'])?_c('div',[_vm._t("FormBuilderPreviewFooter",null,null,{
                                  reset: reset,
                                  invalid: invalid,
                                  validated: validated,
                                  validate: _vm.validerFormulaire,
                                  previsualiserAttestationHonneur: _vm.previsualiserAttestationHonneur,
                                  previsualiserPnCEE: _vm.previsualiserPnCEE,
                                  previsualiserDonneesCalcul: _vm.previsualiserDonneesCalcul,
                                  getValeursSaisies: _vm.getValeursSaisies,
                                  updatePrecariteValues: _vm.updatePrecariteValues,
                                  updateProperty: _vm.updateProperty,
                                  calculer: _vm.submit,
                                  buildFormDataSansHtml: _vm.buildFormDataSansHtml })],2):_vm._e()],1)}}],null,true)})],1)],1),_c('v-dialog',{staticClass:"dialog-size",attrs:{"scrollable":false,"width":"100%","persistent":true,"no-click-animation":true,"fullscreen":false},model:{value:(_vm.previewPdfDialog),callback:function ($$v) {_vm.previewPdfDialog=$$v},expression:"previewPdfDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Prévisualisation de l'attestation sur l'honneur")]),_c('v-card-text',{staticClass:"card-height-700"},[(_vm.loadingPreviewDialog)?_c('v-row',[_c('v-col',[_c('h2',{staticClass:"margin-top-8",attrs:{"align":"center"}},[_vm._v(" Construction du document en cours... ")]),_c('div',{staticClass:"cee-spinner"})])],1):_vm._e(),_c('CeePDFObjectViewer',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingPreviewDialog),expression:"!loadingPreviewDialog"}],attrs:{"url":_vm.urlPreviewPdf}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.previewPdfDialog = false;_vm.urlPreviewPdf = null;_vm.loadingPreviewDialog = false}}},[_vm._v("Fermer")])],1)],1)],1),_c('confirm',{ref:"confirm",attrs:{"only-close":true}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }