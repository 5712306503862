










































import { Component, Prop, Vue } from 'vue-property-decorator';
import { cloneDeep } from 'lodash-es';
import SectionComponentRowItem from './SectionComponentRowItem.vue';
import SectionComponentRow from './SectionComponentRow.vue';
@Component({
    name: 'SectionComponentRowPreview',
    components: {
        SectionComponentRow,
        SectionComponentRowItem,
    },
})
export default class SectionComponentRowPreview extends Vue {
    @Prop({ default: () => ({ schema: {} }) }) public readonly field!: any;
    @Prop({ default: () => ({ schema: {} }) }) public readonly champPreviewConfig!: any;


    public contientDesEnfants = (field: any): boolean => (field && field.children && +field.children.length > 0);

    public get getClassFieldSetRowItem(): any {
        return {
            'form__fieldset_hide_border': !this.contientDesEnfants(this.champPreviewConfig.field),
        };
    }

    public get getClassFieldSetLegentRowItem(): any {
        return {
            'form__fieldsetlegend_hide': !this.contientDesEnfants(this.champPreviewConfig.field),
        };
    }

    public getFieldOccurence(field: any): number {
        if (field && field.name && field.name !== '' && field.children && field.children.length) {
            const groupByParentFieldName = this.internalGroupBy('parentFieldName');
            const result = groupByParentFieldName(Object.values(this.champPreviewConfig.formData || {}))[field.name];

            if (result && result.length && result.length > 0 && (result.length !== field.children.length)) {
                // On fait -1, ca on enlève l'occurence par défaut qui est déjà affiché par le moteur de rendu.
                // Car il n'est pas supprimable.
                return Math.ceil((result.length / field.children.length)) - 1;
            }
        }
        return 0;
    }

    /*
    *
    * https://gist.github.com/JamieMason/0566f8412af9fe6a1d470aa1e089a752
    * */
    public internalGroupBy = (key: string) => (array: any[]) => array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {})

    /**
     * This method is like _.clone except that it recursively clones value.
     *
     * @param value The value to recursively clone.
     * @return Returns the deep cloned value.
     */
    public internalCloneDeep<T>(value: T): T {
        return cloneDeep(value);
    }
}
