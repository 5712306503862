




































































































































































































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
    import { Getter, Action } from 'vuex-class';
    import { cloneDeep } from 'lodash-es';
    import { components, estChampDeTypeNumerique, estChampDeTypeTexte, estChampDeTypeMutiple } from '../components/Fields';
    import { FORM_CONSTANTS } from '@/formsGenerator/components/Constants';

    import { ReferentielStoreMethods, getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
    import { ValeurReferentielle, TypeValeurReferentielle } from '@/shared/models';

    @Component({
        name: 'SidebarComponent',
        components: {
            ...components,
        },
    })
    export default class SidebarComponent extends Vue {
        @Prop({ default: () => ({}) }) public readonly currentField!: any;
        @Prop({ default: () => ({}) }) public readonly formConfig!: any;
        @Prop({ default: () => ({}) }) public readonly bus!: Vue;

        public tabSettingsForms = 'tab-settings-commun';
        public tabSettingsFieldsForms = 'tab-settings-commun';
        public filtreNom = '';
        public isLoading = false;
        public urlOptionsPreSelectionnes = 'obtenirTous';
        public optionsPreSelectionnesLoading = false;

        // Valeurs référentielles.
        @Getter(getterKeyReferentiel(TypeValeurReferentielle.TemplateChamp))
        public entries: ValeurReferentielle[];
        public optionsPreSelectionnes: ValeurReferentielle[] = [];
        @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
        public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>;
        @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES_URI_PATH)
        public getValeursReferentiellesUriPath: (uriPath: string) => Promise<ValeurReferentielle[]>;

        @Watch('apercuUrlSelect', { immediate: false, deep: true })
        public onApercuUrlSelect(val: string) {
            if (val !== null && val !== '' && val && this.currentField) {
                this.optionsPreSelectionnes = new Array<ValeurReferentielle>();
                const item = this.formConfig.listeEnumReferentiel.find((elt: any) => elt.id === this.currentField.ajaxDataUrl);
                if (item) {
                    this.optionsPreSelectionnesLoading = true;
                    this.getValeursReferentiellesUriPath(`${item.id}/${this.urlOptionsPreSelectionnes}`).then((result: ValeurReferentielle[]) => {
                        this.optionsPreSelectionnes = result;
                    }).finally(() => this.optionsPreSelectionnesLoading = false);
                }
            }
        }

        public get nomsChampsDisponibles(): Array<{ id: number; code: string; libelle: string }> { return this.entries; }
        public get estChampDeTypeNumerique(): boolean { return estChampDeTypeNumerique(this.currentField); }
        public get estChampDeTypeTexte(): boolean { return estChampDeTypeTexte(this.currentField); }
        public get estChampDeTypeMutiple(): boolean { return estChampDeTypeMutiple(this.currentField); }

        public created(): void {
            this.chargerChampsDisponibles();
        }

        public chargerChampsDisponibles(): void {
            if (this.isLoading) { return; }

            this.isLoading = true;
            this.getValeursReferentielles(TypeValeurReferentielle.TemplateChamp)
                .then(() => this.isLoading = false)
                .finally(() => this.isLoading = false);
        }

        public get apercuUrlSelect(): string {
            if (this.currentField) {
                const item = this.formConfig.listeEnumReferentiel.find((elt: any) => elt.id === this.currentField.ajaxDataUrl);
                const libelle = item ? item.code : '[ -- Indéfini -- ]';
                return `${this.formConfig.baseApiUrl}${libelle}`;
            }
            return '';
        }

        public closeSettingsCurrentField(): void {
            this.bus.$emit('closeSettingsCurrentField');
        }

        public addSelectOption(): void {
            this.currentField.dataOptions.push(cloneDeep(FORM_CONSTANTS.OptionDefault));
        }

        public removeSelectOption(index: number): void {
            this.currentField.dataOptions.splice(index, 1);
        }

        public sauvegardeOptionsSelectionnes(): boolean {
            if (this.currentField.ajaxDataUrl && this.optionsPreSelectionnes && this.currentField.dataOptions) {
                const url = this.currentField.ajaxDataUrl;
                const templateOperationId = this.formConfig.templateOperationId;
                const templateOperationCode = this.formConfig.templateOperationCode;
                const dataOptions = this.currentField.dataOptions;
                const arreteId = this.formConfig.arreteId;
                this.$http.post(`/${url}/${templateOperationId}/${templateOperationCode}/${arreteId}/sauvegardeOptionsSelectionnes`, {
                    templateOperationId,
                    templateOperationCode,
                    arreteId,
                    dataOptions,
                }).finally(() => {
                    this.currentField.dataOptions = [];
                    this.currentField.dataOptions = dataOptions;
                });
            }
            return false;
        }
    }