import {
    checkTrigger,
    isNullOrEmpty,
    isNullOrUndefined,
    groupBy,
} from './Utils';
import { FormulaireDataItem } from '@/formsGenerator/interfaces';
import { catalogueFonctions } from '@/formsGenerator/components/CatalogueFonctions';
const logger = console;

/**
 * Vérifie la chaîne passée en paramètre est une JSON STRING valide.
 * @param str La chaîne de caractères à tester.
 */
const isJsonString = (str: string) => {
    try {
        if (JSON.parse(str)) {
            return true;
        }
        else {
            return false;
        }
    } catch (e) {
        return false;
    }
}

const regleDeVisibilite = (nomChampIndexe: string, valeurChampCourant: any, champ: any, jsonValidationRule: string, data: any, row: any | null = null, form: any | null = null): boolean => {
    try {
        // Groupe les données par nom de champs au lieu de l'index.
        // Attention il peut avoir plusieurs valeurs par nom, exemple des champs extended.
        const dataGroupbyFieldName = groupBy(Object.values(data), 'parentFieldName');

        // Vérification si c'est une chaîne JSON.
        if (isJsonString(jsonValidationRule)) {
            // Utilisation de JSON LOGIC.
            return checkTrigger({ type: 'json' },
                nomChampIndexe,
                JSON.parse(jsonValidationRule),
                data, dataGroupbyFieldName,
                row,
                null,
                null);
        } else {
            // Récupère nom de la fonction.
            const fnString = 'visibiliteInsideExtended';
            const separatorToVerify = ['?', '&'];
            const parts = (((jsonValidationRule.split(separatorToVerify[0]) || [])[1]) || '')
                          .split(separatorToVerify[1]);

            if (jsonValidationRule.startsWith(fnString)
                && separatorToVerify.every((element) => jsonValidationRule.includes(element))
                && parts && parts.length === 2
                && isJsonString(parts[1])) {

                const operator = parts[0];
                const params = JSON.parse(parts[1]);
                const args: any[] = [
                    data,
                    dataGroupbyFieldName,
                    row as FormulaireDataItem,
                    operator,
                    params
                ];

                // Cherche la fonction, si la trouve , l'exécute en passant les arguments.
                const fn = catalogueFonctions[fnString];
                if (typeof fn === 'function') {
                    const resultat = fn.apply(null, args);
                    // Retourne résultat.
                    return resultat;
                }
                return false
            } else {
                return false;
            }
        }

    } catch (err) {
        logger.warn(` Règle de visibilité, Une erreur s'est produite`, err);
        // Par défaut toujours visible.
        return true;
    }
};

export const possedeConditionDeVisibilite = (champ: any): boolean => {
    return champ && !isNullOrUndefined(champ.conditionalVisibility) && !isNullOrEmpty(champ.conditionalVisibility);
};


export const estVisible = (nomChampIndexe: string, champ: any, data: any, row: any | null = null, form: any | null = null): boolean => {

    if (possedeConditionDeVisibilite(champ)) {
        return regleDeVisibilite(nomChampIndexe, champ.value, champ, champ.conditionalVisibility, data, row, form);
    }
    // Par défaut toujours visible.
    return true;
};
