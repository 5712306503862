






































import { Component } from 'vue-property-decorator';
import { Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { ValidationProvider } from 'vee-validate';
import ChampMixin from '@/formsGenerator/mixins/ChampMixin';

@Component({
    name: 'TextInputField',
    components: {
        ValidationProvider,
    },
    mixins: [ChampMixin],
})
export default class TextInputField extends VueMixinsDecorator<ChampMixin>(ChampMixin) {
    /**
     * Hook appelé quand le composant est monté.
     */
    public mounted(): void {
        this.refChampName = `ref_input_text__${this.currentField.name}_${this.dataIndex}`;
    }
}
