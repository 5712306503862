














































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import SectionComponentRowItem from './SectionComponentRowItem.vue';
import { components } from '../components/Fields';
import { possedeConditionDeVisibilite } from '@/formsGenerator/components/Visibilite';
@Component({
    name: 'SectionComponentRow',
    components: {
        draggable,
        SectionComponentRowItem,
        ...components,
    },
})
export default class SectionComponentRow extends Vue {
    @Prop({ default: () => ({ schema: {} }) }) public readonly form!: any;
    @Prop({ default: () => ({}) }) public readonly formConfig!: any;
    @Prop({ default: () => ({}) }) public readonly formData!: object;
    @Prop({ default: () => -1 }) public readonly sectionIndex!: number;
    @Prop({ default: () => -1 }) public readonly rowParentIndex!: number;
    @Prop({ default: () => -1 }) public readonly rowIndex!: number;
    @Prop({ default: () => 0 }) public readonly nombreOccurence!: number;
    @Prop({ default: () => false }) public readonly isLastElement!: boolean;
    /**
     * Bus individuel de données pour chaque opération.
     */
    @Prop({ default: () => ({}) }) public readonly bus!: Vue;
    /**
     * Bus pour partager les données entre opérations.
     */
    @Prop({ default: () => ({}) }) public readonly busSharedData!: Vue;
    /**
     * Liste des champs qui partagent les données entre Opérations.
     */
    @Prop({ default: () => new Array<string>() }) public readonly fieldsNameSharedData!: string[];

    @Prop({ default: () => false }) public readonly isModeTemplate!: boolean;

    /**
     * Détermine si l'élément est un tableau avec des enfants survolés.
     */
    public aEnfantsSurvoles = false;

    /* Force le rafraîchissement de la sélection. */
    public idChampSelectionne = 0;
    public created(): void {
        this.bus.$on('selectedCurrentField', (item: { sectionIndex: number; activeField: any }) => {
            this.idChampSelectionne = item.activeField.id;
        });
        this.bus.$on('closeSettingsCurrentField', () => {
            this.idChampSelectionne = 0;
        });            
    }

    public possedeConditionDeVisibilite(): boolean {
        return possedeConditionDeVisibilite(this.form);
    }

    public deleteElement(elt: {
        sectionIndex: number;
        rowParentIndex: number;
        rowIndex: number;
        positionIndexArray: number;
        field: any;
    }): void {
        this.bus.$emit('deleteElement', elt);
    }

    public cloneElement(elt: {
        estAsupprimer: boolean;
        fieldName: string;
        sectionIndex: number;
        rowParentIndex: number;
        rowIndex: number; field: any;
    }): void {
        this.bus.$emit('cloneElement', elt);
    }

    public deleteDataRenderElement(elt: {
        estAsupprimer: boolean;
        fieldName: string;
        sectionIndex: number;
        rowParentIndex: number;
        rowIndex: number;
        nombreOccurence: number;
        field: any;
    }): void {
        if (elt) {
            const indexes: string[] = new Array<string>();
            if (this.form && this.form.children && this.form.children.length) {
                this.form.children.forEach((_: any, index: number) => {
                    indexes.push(this.buildDataIndex(index));
                });
                if (indexes && indexes.length > 0) {
                    this.bus.$emit('deleteDataRenderElement', indexes);
                }
            }
        }
    }

    public cloneDataRenderElement(elt: {
        fieldName: string;
        sectionIndex: number;
        rowParentIndex: number;
        rowIndex: number;
        nombreOccurence: number;
        field: any;
    }): void {
        this.bus.$emit('cloneDataRenderElement', elt);
    }

    public editActiveField(editActiveFieldParam: { sectionIndex: number; activeField: any }): void {
        this.$emit('editActiveFieldEvent', editActiveFieldParam);
    }

    public configureActiveField(field: any): void {
        if (this.isModeTemplate) {
            this.bus.$emit('selectedCurrentField', { sectionIndex: this.sectionIndex, activeField: field });
        }
    }

    public openDialogChoosenFields(): void {
        const item = {
            sectionIndex: this.sectionIndex,
            rowParentIndex: this.rowIndex,
            rowIndex: 0,
            positionIndexArray: 0,
            field: null as any,
        };
        this.bus.$emit('openDialogChoosenFields', item);
    }

    public isArrayField(): boolean {
        return (this.form.fieldType === 'ArrayField');
    }

    public buildDataIndex(positionIndexArray: number | null): string {
        const listIndex = [this.sectionIndex, this.rowParentIndex];
        if (this.rowIndex !== null && this.rowIndex >= 0) {
            listIndex.push(this.rowIndex);
        }
        if (positionIndexArray !== null && positionIndexArray >= 0) {
            listIndex.push(positionIndexArray);
        }
        return `${listIndex.join('_')}`;
    }

    public buildFieldName(sectionIndex: number,
        rowIndex: number,
        positionIndexArray: number | null,
        childrenIndex: number | null,
        childFieldName: string | null): string {
        if (positionIndexArray !== null && typeof positionIndexArray !== 'undefined'
            && (childrenIndex !== null && typeof childrenIndex !== 'undefined' && childrenIndex >= 0 && childrenIndex >= 0)) {
            return `${this.form.name}.[${rowIndex}].${childFieldName}`;
        } else {
            return this.form.name;
        }
    }

    public getClassRowItem(element: any): any {
        return {
            'form__group': this.isModeTemplate,
            selectionne: this.idChampSelectionne === element.id,
            'enfants-survoles': element.fieldType === 'ArrayField' && this.aEnfantsSurvoles,
        };
    }

    /* Sélectionne la section au survol de la souris. */
    /* Pas possible de faire ça en CSS directement, on va donc traquer en JS l'entrée sur les enfants du composant. */
    public onMouseOverChild() {
        this.aEnfantsSurvoles = true;
    }
    public onMouseLeaveChild() {
        this.aEnfantsSurvoles = false;
    }       
}
