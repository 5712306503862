











































import { Component } from 'vue-property-decorator';
import { Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { ValidationProvider } from 'vee-validate';
import ChampMixin from '@/formsGenerator/mixins/ChampMixin';

@Component({
    name: 'NumberInputField',
    components: {
        ValidationProvider,
    },
    mixins: [ChampMixin],
})
export default class NumberInputField extends VueMixinsDecorator<ChampMixin>(ChampMixin) {

    public onKeyPress($event: KeyboardEvent): boolean {
        const regex = /^[0-9]*$/;
        const value = $event.key || '';
        const resultatExecution = regex.test(value);
        if (!resultatExecution) {
            $event.stopImmediatePropagation();
            $event.preventDefault();
            $event.returnValue = false;
            $event.cancelBubble = true;
            return false;
        }
        return true;
    }
    /**
     * Hook appelé quand le composant est monté.
     */
    public mounted(): void {
        this.refChampName = `ref_number__${this.currentField.name}_${this.dataIndex}`;
    }
}
