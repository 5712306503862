














import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    name: 'ArrayField',
})
export default class ArrayField extends Vue {
    @Prop({ default: '' }) public readonly control!: string;
    @Prop({ default: '' }) public readonly labelPosition!: string;
}
