import { extend } from "vee-validate";
const logger = console;
import { checkTrigger, groupBy } from './Utils';
const rules = {
    conditionalValidation: {
        getMessage(field: any, args: any) {
            return 'conditionalValidation';
        },
        validate(value: any, [jsonValidationRule]: any) {
            try {
                const data = { value };
                const dataGroupbyFieldName = groupBy(Object.values(data), 'parentFieldName');
                return checkTrigger({ type: 'json' }, 'fieldName', JSON.parse(jsonValidationRule), data, dataGroupbyFieldName, null, null, null);
            } catch (err) {
                // tslint:disable-next-line:no-console
                logger.warn(` ConditionalValidation, Une erreur s'est produite`, err);
                return false;
            }
        },
    },
    customValidationRule: {
        getMessage(field: any, args: any) {
            return '';
        },
        validate(value: any, [jsonValidationRule]: any) {
            try {
                const data = { value };
                const dataGroupbyFieldName = groupBy(Object.values(data), 'parentFieldName');
                return checkTrigger({ type: 'json' }, 'fieldName', JSON.parse(jsonValidationRule), data, dataGroupbyFieldName, null, null, null);

            } catch (err) {
                // tslint:disable-next-line:no-console
                logger.warn(`CustomValidationRule, Une erreur s'est produite`, err);
                return false;
            }
        },
    },
};

const customValidationRules = () => {
    Object.keys(rules).forEach((rule: any) => {
        extend(rule, (rules as any)[rule]);
    });
};

export default customValidationRules;

const isNullOrUndefined = (value: any): boolean => (typeof value === 'undefined' || value === null);
const isNullOrEmpty = (s: string) => isNullOrUndefined(s) || (s == null || s === '' || s === ' ');
const isBoolean = (value: any): boolean => ('boolean' === typeof value);

interface ValidationFieldType {
    conditionalValidation: string | null | undefined;
    required: boolean | null | undefined;   
    isUnique: boolean | null | undefined;
    minimumLength: number | null | undefined;
    maximumLength: number | null | undefined;
    minimumValue: number | null | undefined;
    maximumValue: number | null | undefined;
    minimumWorld: number | null | undefined;
    maximumWorld: number | null | undefined;
    regularExpressionPattern: string | null | undefined;
    veeValidateValidation: string | null | undefined;
    customValidation: string | null | undefined;
    validateOn: string | null | undefined;
    customerrorMessage: string | null | undefined;
}

export function buildRules(validation: ValidationFieldType, data: any): string[] {
    if (validation) {
        // validation.veeValidateValidation = window.location.href.includes("dossier");
        return [
            !isNullOrUndefined(validation.conditionalValidation) && validation.conditionalValidation ? `conditionalValidation:${validation.conditionalValidation}` : '',
            !isNullOrUndefined(validation.required) && isBoolean(validation.required) && validation.required ? 'required' : '',          
            !isNullOrUndefined(validation.isUnique) && isBoolean(validation.isUnique) && validation.isUnique ? 'required' : '',
            // Traitement spécifique pour permettre d'activer la validation que au niveau du dossier ou de la simulation.
            !isNullOrUndefined(validation.veeValidateValidation) && validation.veeValidateValidation === "required:dossier" && window.location.href.includes("dossier") ? 'required' : '',
            !isNullOrUndefined(validation.veeValidateValidation) && validation.veeValidateValidation === "required:simulation" && window.location.href.includes("simulateur") ? 'required' : '',

            !isNullOrUndefined(validation.veeValidateValidation) && validation.veeValidateValidation !== "required:dossier" && validation.veeValidateValidation !== "required:simulation" && validation.veeValidateValidation ? `${validation.veeValidateValidation}` : '',
            !isNullOrUndefined(validation.minimumLength) && validation.minimumLength ? `min:${validation.minimumLength}` : '',
            !isNullOrUndefined(validation.maximumLength) && validation.maximumLength ? `max:${validation.maximumLength}` : '',
            !isNullOrUndefined(validation.minimumValue) && validation.minimumValue ? `min_value:${validation.minimumValue}` : '',
            !isNullOrUndefined(validation.maximumValue) && validation.maximumValue ? `max_value:${validation.maximumValue}` : '',
            !isNullOrUndefined(validation.maximumWorld) && validation.customValidation ? `customValidationRule:${validation.conditionalValidation}` : '',
        ];
    } else {
        return new Array<string>();
    }

}

export function buildRulesAsVeeValidateString(validation: ValidationFieldType, data: any, row: any, form: any): string {
    const filteredRules = (buildRules(validation, data) || new Array<string>()).filter((rule) => !isNullOrEmpty(rule));
    if (filteredRules && filteredRules.length > 0) {
        // On fait un distinct avant de renvoyer.
        return `${filteredRules.filter((value: string, index: number, self: string[]) => {
            return self.indexOf(value) === index;
        }).join('|')}`;
    } else {
        return '';
    }
}
