



































































import { Component, Prop, Vue } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import SectionComponentRow from './SectionComponentRow.vue';
import SectionComponentRowItem from './SectionComponentRowItem.vue';
import { components } from '../components/Fields';

@Component({
    name: 'SectionComponent',
    components: {
        draggable,
        SectionComponentRow,
        SectionComponentRowItem,
        ...components,
    },
})
export default class SectionComponent extends Vue {
    @Prop({ default: () => ({}) }) public readonly fields!: object;
    @Prop({ default: () => ({}) }) public readonly formConfig!: object;
    @Prop({ default: () => ({}) }) public readonly formData!: object;
    @Prop({ default: () => new Array<{ hasError: boolean; message: string | string[] }>() })
    public readonly validationsFormConfig!: Array<{ hasError: boolean; message: string | string[] }>;
    @Prop({ default: () => ({}) }) public readonly bus!: Vue;
    @Prop({ default: () => false }) public readonly isModeTemplate!: boolean;

    /**
     * Bus pour partager les données entre opérations.
     */
    @Prop({ default: () => ({}) }) public readonly busSharedData!: Vue;

    public panelSections: boolean[] = ((this.formConfig as any).sections || []).map((item: { estConfigurable: boolean }, index: number) => {
        return { index, estConfigurable: item.estConfigurable };
    }).filter((item: { estConfigurable: boolean; index: number }) => item.estConfigurable)
        .map((item: { estConfigurable: boolean; index: number }) => item.index);

    /**
     * Dictionnaire des icônes par secteur, à factoriser.
     */
    private readonly secteurs: { [code: string]: string } = {
        'transport': 'bus',
        'reseaux': 'project-diagram',
        'residentiel': 'building',
        'industrie': 'industry',
        'agriculture': 'tractor',
        'tertiaire': 'school'
    };

    public sortElementOptions: any = {
        group: { name: 'formbuilder', pull: false, put: true },
        sort: true,
        // handle: ".form__actionitem--move"
    };

    /**
     * Récupère l'icône en fonction du secteur affiché.
     * @param secteur
     */
    public getIconeSecteur(secteur: string): string {
        return 'fa fa-' + this.secteurs[secteur.toLowerCase()];
    }

    public openDialogChoosenFields(sectionIndex: number, rowIndex: number): void {
        this.bus.$emit('openDialogChoosenFields', { sectionIndex, rowIndex, positionIndexArray: null, field: null });
    }
}
