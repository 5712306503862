
































import { Action } from 'vuex-class';
import { ReferentielStoreMethods } from '@/store/modules/referentiel/ReferentielStore';
import { ValeurReferentielle } from '@/shared/models';

import { Component, Watch } from 'vue-property-decorator';
import { Mixins as VueMixinsDecorator } from 'vue-mixin-decorator';
import { ValidationProvider } from 'vee-validate';
import ChampMixin from '@/formsGenerator/mixins/ChampMixin';

@Component({
    name: 'RadioButtonField',
    components: {
        ValidationProvider,
    },
    mixins: [ChampMixin],
})
export default class RadioButtonField extends VueMixinsDecorator<ChampMixin>(ChampMixin) {
    public loading = false;
    public dataOptions: any = [];
    
    // Méthode de récupération des données de la liste.
    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES_URI_PATH)
    public getDataOptions: (uriPath: string) => Promise<ValeurReferentielle[]>
    /**
     * Watcher sur le champ courant.
     */
    @Watch('currentField', { immediate: true, deep: true })
    public onIsAjaxChanged() {
        this.loadDataOptions();
    }
    /**
     * Charge les options du RadioButton.
     */
    public loadDataOptions(): void {
        if (this.currentField.isAjax && this.urlSelect !== null) {
            this.loading = true;
            this.getDataOptions(this.urlSelect).then((result: ValeurReferentielle[]) => {
                // Met à jour les options disponibles si celles-ci ont changé.
                if (this.dataOptions !== result) {
                    this.dataOptions = result;
                }
            }).finally(() => this.loading = false);
        }
        // Met à jour les options disponibles si celles-ci ont changé.
        else if (this.currentField.dataOptions && this.dataOptions !== this.currentField.dataOptions) {
            this.dataOptions = this.currentField.dataOptions;
        }
    }
    /**
     * Obtient l'URL pour charger les options.
     */
    public get urlSelect(): string | null {
        const item = this.formConfig.listeEnumReferentiel.find((elt: any) => elt.id === this.currentField.ajaxDataUrl);
        if (item) {
            const url = (`${this.formConfig.baseApiUrl}${item.code}`)
                .replace(/codeOperation/g, this.formConfig.templateOperationCode)
                .replace(/arreteId/g, this.formConfig.arreteId);
            return url;
        }
        return null;
    }
    /**
     * Événement lorsque le radio button change
     * @param value
     */
    public async input(value: any): Promise<void> {
        if (value != null && typeof value !== 'undefined') {
            await this.onInput(value);
        }
    }
    /**
     * Hook appelé quand le composant est monté.
     */
    public mounted(): void {
        this.refChampName = `ref_radiobutton__${this.currentField.name}_${this.dataIndex}`;
        if (!this.dataOptions || this.dataOptions.length <= 0) {
            return;
        }
        if (this.dataOptions) {
            this.loadDataOptions();
        }
    }
}
