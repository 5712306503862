


























































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import cloneDeep from 'lodash-es/cloneDeep';
import omit from 'lodash-es/omit';

import FormBuilderPreview from '@/formsGenerator/template/FormBuilderPreview.vue';
import SectionComponent from '@/formsGenerator/configurators/SectionComponent.vue';
import SidebarComponent from '@/formsGenerator/configurators/SidebarComponent.vue';
import { fields } from '@/formsGenerator/components/Fields';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { UserProfile } from '@/store/modules/auth/types';
import { Profils } from '@/shared/enums';

@Component({
    name: 'FormBuilderTemplate',
    components: { FormBuilderPreview, SidebarComponent, SectionComponent },
})
export default class FormBuilderTemplate extends Vue {
    @Prop({ default: () => ({}) }) public readonly champs!: any;
    @Prop({ default: () => ({}) }) public readonly formConfig!: any;
    @Prop({ default: () => ({}) }) public readonly formData!: object;
    @Prop({ default: () => new Array<{ hasError: boolean; message: string | string[] }>() })
    public readonly validationsFormConfig!: Array<{ hasError: boolean; message: string | string[] }>;
    /**
     * Bus individuel de données pour chaque opération.
     */
    @Prop({ default: () => ({}) }) public readonly bus!: Vue;
    /**
     * Bus pour partager les données entre opérations.
     */
    @Prop({ default: () => ({}) }) public readonly busSharedData!: Vue;
    /**
     * Liste des champs qui partagent les données entre Opérations.
     */
    @Prop({ default: () => new Array<string>() }) public readonly fieldsNameSharedData!: string[];
    // Permet de faire en sorte que le template ait accès à l'ENUM.
    public Profils = Profils;

    /** Récupération du profil de l'utilisateur courant. */
    @Getter(AuthStoreMethods.USER_PROFILE)
    private getUserProfile: Promise<UserProfile>;

    // Permet de savoir si l'utilisateur connecté est un administrateur;
    public estAdmin = false;
    public loading = false;

    public currentSectionIndex: number | null = null;
    public currentSectionRowParentIndex: number | null = null;
    public currentSectionRowIndex: number | null = null;
    public currentPositionIndexArray: number | null = null;
    public tabTemplatingForms: string = null;

    public currentField: object | null = null;
    public dialogChoosenFields = false;
    public selectedFields: any = [];
    public fields: any = fields;
    public champsDisponibles: any = [
        { header: 'Composants de base' },
        ...Object.values(this.champs.baseComponents as any),
        { divider: true },
        { header: 'Composants avancés' },
        ...Object.values(this.champs.advancedComponents as any),
    ];

    public alert(message?: any): void {
        window.alert(message);
    }
    public get getDataKeys(): string[] {
        return Object.keys(this.formData || {});
    }

    public get getDataEntries(): Array<[string, any]> {
        return Object.entries(this.formData || {});
    }

    /** Récupère les informations de l'utilisateur connecté. */
    public mounted(): void {
        this.getUserProfile.then((profile: UserProfile) => {
            if (profile) {
                this.estAdmin = profile.isAdmin;
                if (this.estAdmin) {
                    this.tabTemplatingForms = 'tab-design';
                }
                else {
                    this.tabTemplatingForms = 'tab-preview';
                }
            }
        });
    }

    private created(): void {
        this.bus.$on('selectedCurrentField', (item: { sectionIndex: number; activeField: any }) => {
            this.currentField = item.activeField;
        });

        this.bus.$on('closeSettingsCurrentField', () => {
            this.currentField = null;
        });

        this.bus.$on('openDialogChoosenFields', (item: {
            sectionIndex: number;
            rowParentIndex: number;
            rowIndex: number;
            positionIndexArray: number | null;
            field: any;
        }) => {
            this.openDialogChoosenFields(item);
        });

        this.bus.$on('deleteElement', (item: { sectionIndex: number; rowParentIndex: number; rowIndex: number; positionIndexArray: number; field: any }) => {
            const { sectionIndex, rowParentIndex, rowIndex, positionIndexArray, field } = item;
            if (rowParentIndex !== null && rowParentIndex >= 0 && rowIndex !== null && rowIndex >= 0) {
                if (positionIndexArray !== null && positionIndexArray >= 0) {
                    (this.formConfig.sections[sectionIndex].schemas || [])[rowParentIndex].children.splice(rowIndex, 1);
                } else if (rowParentIndex === rowIndex) {
                    (this.formConfig.sections[sectionIndex].schemas || []).splice(rowParentIndex, 1);
                }
                const updateFormDataItem = {
                    estAsupprimer: true,
                    fieldName: field.name,
                    parentFieldName: field.name,
                    sectionIndex,
                    rowParentIndex,
                    rowIndex,
                    positionIndexArray,
                    childrenIndex: positionIndexArray,
                    field,
                    value: '',
                };
                this.bus.$emit('updateFormData', updateFormDataItem);

            }
        });

        this.bus.$on('cloneElement', (item: {
            sectionIndex: number;
            rowParentIndex: number;
            rowIndex: number;
            positionIndexArray: number | null;
            field: any;
        }) => {
            const { sectionIndex, rowParentIndex, rowIndex, positionIndexArray, field } = item;
            if (sectionIndex >= 0 && field) {
                this.addSchema(sectionIndex, rowParentIndex, rowIndex, positionIndexArray, cloneDeep(omit(field, ['id'])));
            }
        });
    }

    private openDialogChoosenFields(item: {
        sectionIndex: number;
        rowParentIndex: number;
        rowIndex: number;
        positionIndexArray: number | null;
        field: any;
    }): void {
        this.dialogChoosenFields = true;
        this.currentSectionIndex = item.sectionIndex;
        this.currentSectionRowParentIndex = item.rowParentIndex;
        this.currentSectionRowIndex = item.rowIndex;
        this.currentPositionIndexArray = item.positionIndexArray;
    }

    private saveDialogChoosenFields(): void {
        if (typeof this.currentSectionIndex !== 'undefined' && this.currentSectionIndex !== null && this.currentSectionIndex >= 0) {
            this.addFieldRowToSection(this.currentSectionIndex,
                (this.currentSectionRowParentIndex !== null && typeof this.currentSectionRowParentIndex !== 'undefined') ? this.currentSectionRowParentIndex : -1,
                (this.currentSectionRowIndex !== null && typeof this.currentSectionRowIndex !== 'undefined') ? this.currentSectionRowIndex : -1,
                this.currentPositionIndexArray,
                this.selectedFields);
        }
        this.closeDialogChoosenFields();
    }

    private closeDialogChoosenFields(): void {
        this.dialogChoosenFields = false;
        this.currentSectionIndex = null;
        this.currentSectionRowParentIndex = null;
        this.currentSectionRowIndex = null;
        this.currentPositionIndexArray = null;
        this.selectedFields = new Array<string>();
    }

    private addFieldRowToSection(
        currentSectionIndex: number,
        currentSectionRowParentIndex: number,
        currentSectionRowIndex: number,
        currentPositionIndexArray: number | null,
        selectedFields: string[]): void {
        if (currentSectionIndex >= 0 && (selectedFields && selectedFields.length > 0)) {
            const filterFields = ([...fields.base, ...fields.advanced]).filter(item => selectedFields.includes(item.fieldType));
            if (filterFields && filterFields.length) {
                filterFields.forEach(filterField => this.addSchema(currentSectionIndex,
                    currentSectionRowParentIndex,
                    currentSectionRowIndex,
                    currentPositionIndexArray,
                    cloneDeep(filterField)));
            }
        }
    }

    private addSchema(sectionIndex: number, rowParentIndex: number, rowIndex: number, positionIndexArray: number | null, field: any): void {
        if (sectionIndex >= 0 && field && this.formConfig.sections && this.formConfig.sections[sectionIndex]) {
            //
            if (rowParentIndex >= 0 && rowIndex >= 0 && positionIndexArray !== null && positionIndexArray >= 0) {
                this.formConfig.sections[sectionIndex].schemas[rowParentIndex].children.push(field);
            } else {
                this.formConfig.sections[sectionIndex].schemas.push(field);
            }
        }
    }
}
