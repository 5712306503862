
import { FormulaireDataItem } from '@/formsGenerator/interfaces';
import { buildFinalFieldIndex, isNullOrUndefined, groupBy } from '@/formsGenerator/components/Utils';

enum champs {
    NUM_DEPARTEMENT = 'NUM_DEPARTEMENT',
    EQUIPEMENT = 'EQUIPEMENT',
    EQUIPEMENT_NEUF_OU_EXISTANT = 'EQUIPEMENT_NEUF_OU_EXISTANT',
    TYPE_RENOVATION ='TYPE_RENOVATION',
    PUISSANCE_CHAUFFERIE_APRES_TRAVAUX = 'PUISSANCE_CHAUFFERIE_APRES_TRAVAUX',
    PUISSANCE_CHAUDIERE_ELIGIBLE = 'PUISSANCE_CHAUDIERE_ELIGIBLE',
    PUISSANCE_CHAUDIERE_VALORISEE = 'PUISSANCE_CHAUDIERE_VALORISEE',
    PUISSANCE_NV_CHAUDIERE_ELIGIBLE = 'PUISSANCE_NV_CHAUDIERE_ELIGIBLE',
    PUISSANCE_UNITAIRE_APPAREIL = 'PUISSANCE_UNITAIRE_APPAREIL',
    PUISSANCE_ELECTRIQUE_COMPRESSEUR = 'PUISSANCE_ELECTRIQUE_COMPRESSEUR',
    PUISSANCE_THERMIQUE_DEJA_RECUPEREE = 'PUISSANCE_THERMIQUE_DEJA_RECUPEREE',
    PUISSANCE_UNITAIRE_LUMINAIRE_LED = 'PUISSANCE_UNITAIRE_LUMINAIRE_LED',
    PUISSANCE_PAC_INP = 'PUISSANCE_PAC_INP',
    PUISSANCE_PAC = 'PUISSANCE_PAC',
    PUISSANCE_NOMINALE_TOTAL = 'PUISSANCE_NOMINALE_TOTAL',
    PUISSANCE_NOMINALE_PAC = 'PUISSANCE_NOMINALE_PAC',
    NB_LUMINAIRES_LED = 'NB_LUMINAIRES_LED',
    NB_APPAREILS = 'NB_APPAREILS',
    SURFACE_CHAUFFEE = 'SURFACE_CHAUFFEE',
    RETOUR_ORGANISME_CONTROLE = 'RETOUR_ORGANISME_CONTROLE',
    ETAS_INP = "ETAS_INP",
    COEFFICIENT_PERFORMANCE = "COEFFICIENT_PERFORMANCE",
    CONFORMITE_APRES_CORRECTION = "CONFORMITE_APRES_CORRECTION",
    PUISSANCE_FRIGORIFIQUE_POSITIVE = "PUISSANCE_FRIGORIFIQUE_POSITIVE",
    PUISSANCE_FRIGORIFIQUE_NEGATIVE = "PUISSANCE_FRIGORIFIQUE_NEGATIVE",
    SURFACE_ISOLANT_INITIALE = "SURFACE_ISOLANT_INITIALE",
    VOLUME_CLASSIQUE_INITIAL = "VOLUME_CLASSIQUE_INITIAL",
    VOLUME_PRECARITE_INITIAL = "VOLUME_PRECARITE_INITIAL",
    VOLUME_TOTAL_INITIAL = "VOLUME_TOTAL_INITIAL",
}

interface dataType { [key: string]: FormulaireDataItem; }
/**
 * { [index: number]: { [key: string]: FormulaireDataItem } }
 * @param nomChamp
 * @param datas
 */
const recupererTableauChampParNom = (nomChamp: string, datas: dataType): dataType[] => {

    if (isNullOrUndefined(nomChamp)) {
        throw new Error('nomChamp');
    }

    if ((datas == null)) {
        throw new Error('datas');
    }

    const listeChamps = Object.values(datas).filter((item: { parentFieldName: string }) => item.parentFieldName === nomChamp);
    const tableau = groupBy(listeChamps, 'rowIndex');
    return tableau;
};


/**
 * Permet de récupérer la valeur d'un champ en fonction de son nom dans le formulaire.
 *
 * @param nomChamp Le nom du champ.
 * @param valeurs Liste données du formulaire.
 * @returns {number |string | null}
 */
const recupererValeurChampParNom = (nomChamp: string, valeurs: dataType): number | string | null => recupererChampParNom(nomChamp, valeurs).value;

/**
 * Permet de récupérer la valeur d'un champ en fonction de son nom dans le formulaire.
 *
 * @param nomChamp Le nom du champ.
 * @param valeurs Liste données du formulaire.
 * @returns {any | null}
 */
const recupererChampParNom = (nomChamp: string, valeurs: dataType): any | null => {

    if (isNullOrUndefined(nomChamp)) {
        throw new Error('nomChamp');
    }

    if ((valeurs == null)) {
        throw new Error('valeurs');
    }

    const champTrouve: any = Object.values(valeurs).filter((item: FormulaireDataItem) => item.fieldName === nomChamp)
        .reduce((previousValue: FormulaireDataItem, currentValue: FormulaireDataItem, currentIndex: number, array: FormulaireDataItem[]) => {
            if (array.length > 1) {
                throw new Error(`Le champ ${nomChamp} existe en doublon.`);
            }
            return array[0];
        }, {} as FormulaireDataItem);

    if (champTrouve === null || typeof champTrouve === 'undefined' || !champTrouve) {
        throw new Error(`Le champ ${nomChamp} n'est pas trouvé dans la liste de champs.`);
    }

    return champTrouve;
};

/**
 * Permet de récupérer la valeur d'un champ en fonction de sa position dans le formulaire.
 *
 * @param nomChamp Le nom du champ.
 * @param valeurs Liste données du formulaire.
 * @returns {number |string | null }
 */
const recupererValeurChampParIndex = (index: string, valeurs: dataType): number | string | null => {
    if (isNullOrUndefined(index)) {
        throw new Error('index');
    }

    if ((valeurs == null)) {
        throw new Error('valeurs');
    }

    return valeurs[index].value;
};




/**
 * Fait la somme de puissance de la ligne précédente d'un tableau(repeater), pour la setter dans la ligne suivante. Pour l'opération IND-UT-117.
 * @param datasParIndex {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param datasParNom {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param ligneCourante {FormulaireDataItem} Les infos des la ligne courante.
 * @returns {number | null}
 */
const calculSommePuissancesRecuperees = (datasParIndex: dataType, datasParNom: dataType, ligneCourante: FormulaireDataItem): number | null => {
    if (ligneCourante && ligneCourante.rowIndex >= 0) {
        const { sectionIndex, rowParentIndex, rowIndex, positionIndexArray } = ligneCourante;

        const indexPuissanceDejaRecuperee = buildFinalFieldIndex(sectionIndex, rowParentIndex, (rowIndex - 1), positionIndexArray);
        const indexPuissanceSystemeRecuperation = buildFinalFieldIndex(sectionIndex, rowParentIndex, (rowIndex - 1 ), positionIndexArray + 1);
        const valuePuissanceDejaRecuperee = datasParIndex[indexPuissanceDejaRecuperee] && datasParIndex[indexPuissanceDejaRecuperee].value;
        const valuePuissanceSystemeRecuperation = datasParIndex[indexPuissanceSystemeRecuperation] && datasParIndex[indexPuissanceSystemeRecuperation].value;
        if (indexPuissanceDejaRecuperee && valuePuissanceDejaRecuperee && valuePuissanceSystemeRecuperation) {
            return (+valuePuissanceDejaRecuperee) + (+valuePuissanceSystemeRecuperation);
        }
    }
    return null;

};

/**
 * Calcule coefficient R (Exemple BAR-TH-107).
 * @param datasParIndex {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param datasParNom {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param ligneCourante {FormulaireDataItem} Les infos des la ligne courante.
 * @returns {number | null}
 */
const calculCoefficientThermique = (datasParIndex: dataType, datasParNom: dataType, ligneCourante: FormulaireDataItem): number | string | null => {

    if (ligneCourante && ligneCourante.rowIndex >= 0) {

        const puissanceTotaleChaufferie: number = Number(recupererValeurChampParNom(champs.PUISSANCE_CHAUFFERIE_APRES_TRAVAUX, datasParIndex));
        const puissanceTotaleChaudiere: number = Number(recupererValeurChampParNom(champs.PUISSANCE_CHAUDIERE_ELIGIBLE, datasParIndex));
        const typeRenovation: number = Number(recupererValeurChampParNom(champs.TYPE_RENOVATION, datasParIndex));

        const tableau = recupererTableauChampParNom(champs.EQUIPEMENT, datasParIndex);
        // Récupère le tableau de données.
        if (tableau !== null && typeof tableau !== 'undefined') {
            // Récupère la ligne correspondante..
            type tableRowType = [string, [string, dataType]];
            const entries: tableRowType[] = Object.entries(Object.entries(tableau));

            if (entries) {
                const tableRow = entries.map(([index, [, value]]) => {
                    return { index: (parseInt(index, 10)), value };
                }).find((element: { index: number, value: dataType }) => element && (element.index === ligneCourante.rowIndex)).value;

                if (tableRow && typeRenovation === 1 &&  recupererValeurChampParNom(champs.PUISSANCE_CHAUDIERE_VALORISEE, tableRow)) {
                    // Récupère la valeur du champ dans la ligne du tableau.
                    const puissanceChaudiereValorisee = recupererValeurChampParNom(champs.PUISSANCE_CHAUDIERE_VALORISEE, tableRow) as number;

                    // Exécution.
                    const rapportChaufferie: number = puissanceTotaleChaudiere / puissanceTotaleChaufferie;
                    if (rapportChaufferie < 0.33) {
                        return (puissanceChaudiereValorisee / puissanceTotaleChaufferie).toFixed(4);
                    } else {
                        return (puissanceChaudiereValorisee / puissanceTotaleChaudiere).toFixed(4);
                    }
                }

                if (tableRow && typeRenovation === 2) {
                    if(recupererValeurChampParNom(champs.PUISSANCE_NV_CHAUDIERE_ELIGIBLE, tableRow)) {
                        // Récupère la valeur du champ dans la ligne du tableau.
                        const puissanceChaudiereEligible = recupererValeurChampParNom(champs.PUISSANCE_NV_CHAUDIERE_ELIGIBLE, tableRow) as number;

                        // Exécution.                    
                        return (puissanceChaudiereEligible / puissanceTotaleChaufferie).toFixed(4);
                    }
                    if (recupererValeurChampParNom(champs.PUISSANCE_CHAUDIERE_VALORISEE, tableRow)) {
                        // Récupère la valeur du champ dans la ligne du tableau.
                        const puissanceChaudiereEligible = recupererValeurChampParNom(champs.PUISSANCE_CHAUDIERE_VALORISEE, tableRow) as number;

                        // Exécution.                    
                        return (puissanceChaudiereEligible / puissanceTotaleChaufferie).toFixed(4);
                    }
                }
            }
        }
    }
    return null;
};

/**
 * Calcule coefficient R PAC (Exemple BAR-TH-150).
 * @param datasParIndex {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param datasParNom {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param ligneCourante {FormulaireDataItem} Les infos des la ligne courante.
 * @returns {number | null}
 */
const calculCoefficientThermiquePac = (datasParIndex: dataType, datasParNom: dataType, ligneCourante: FormulaireDataItem): number | string | null => {

    if (ligneCourante && ligneCourante.rowIndex >= 0) {
        const pNominaleTotale: number = recupererValeurChampParNom(champs.PUISSANCE_NOMINALE_TOTAL, datasParIndex) as number;
        const pNominaleUnitaire: number = recupererValeurChampParNom(champs.PUISSANCE_NOMINALE_PAC, datasParIndex) as number;

        const tableau = recupererTableauChampParNom(champs.EQUIPEMENT, datasParIndex);
        // Récupère le tableau de données.
        if (tableau !== null && typeof tableau !== 'undefined') {
            // Récupère la ligne correspondante..
            type tableRowType = [string, [string, dataType]];
            const entries: tableRowType[] = Object.entries(Object.entries(tableau));

            if (entries) {
                const tableRow = entries.map(([index, [, value]]) => {
                    return { index: (parseInt(index, 10)), value };
                }).find((element: { index: number, value: dataType }) => element && (element.index === ligneCourante.rowIndex)).value;

                if (recupererValeurChampParNom(champs.PUISSANCE_PAC_INP, tableRow)) {
                    // Récupère la valeur du champ dans la ligne du tableau.
                    const puissancePac = recupererValeurChampParNom(champs.PUISSANCE_PAC_INP, tableRow) as number;

                    // Exécution.
                    const rapportPuissance: number = pNominaleUnitaire / pNominaleTotale;
                    if (rapportPuissance < 0.4) {
                        return (puissancePac / pNominaleTotale).toFixed(4);
                    } else {
                        return (puissancePac / pNominaleUnitaire).toFixed(4);
                    }
                }


            }
        }
    }
    return null;
};

/**
 * Calcule coefficient R PAC (Exemple BAR-TH-166).
 * @param datasParIndex {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param datasParNom {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param ligneCourante {FormulaireDataItem} Les infos des la ligne courante.
 * @returns {number | null}
 */
const calculCoefficientThermiquePacBarTh166 = (datasParIndex: dataType, datasParNom: dataType, ligneCourante: FormulaireDataItem): number | string | null => {
    let coeffR = 1;
    if (ligneCourante && ligneCourante.rowIndex >= 0) {
        const pNominaleTotale: number = recupererValeurChampParNom(champs.PUISSANCE_NOMINALE_TOTAL, datasParIndex) as number;
        const pNominaleUnitaire: number = recupererValeurChampParNom(champs.PUISSANCE_PAC, datasParIndex) as number;
        const equipementNeufOuExistant: string = recupererValeurChampParNom(champs.EQUIPEMENT_NEUF_OU_EXISTANT, datasParIndex) as string;
        if (equipementNeufOuExistant === 'true') {
            if (pNominaleUnitaire < 0.4 * pNominaleTotale) {
                coeffR = pNominaleUnitaire / pNominaleTotale;
            }            
        }
    }
    return coeffR;
};

/**
 * Calcule la puissance totale des appareils de chauffage (Exemple IND-BA-117).
 * @param datasParIndex {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param datasParNom {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param ligneCourante {FormulaireDataItem} Les infos des la ligne courante.
 * @returns {number | null}
 */
const calculPuissanceTotaleChauffage = (datasParIndex: dataType, datasParNom: dataType, ligneCourante: FormulaireDataItem): number | string | null => {
    let puissanceTotale: number = 0;
    if (ligneCourante && ligneCourante.rowIndex >= 0) {

        const tableau = recupererTableauChampParNom(champs.EQUIPEMENT, datasParIndex);
        // Récupère le tableau de données.
        if (tableau !== null && typeof tableau !== 'undefined') {
            for (const [clef, element] of Object.entries(tableau)) {
                if ((recupererValeurChampParNom(champs.PUISSANCE_UNITAIRE_APPAREIL, element)) && (recupererValeurChampParNom(champs.NB_APPAREILS, element))) {
                    // Récupère la valeur du champ dans la ligne du tableau.
                    const puissanceUnitaire = recupererValeurChampParNom(champs.PUISSANCE_UNITAIRE_APPAREIL, element) as number;
                    const nbAppareils = recupererValeurChampParNom(champs.NB_APPAREILS, element) as number;
                    puissanceTotale += puissanceUnitaire * nbAppareils;
                }
            }
        };
    }
    return (puissanceTotale).toFixed(4);
}

/**
 * Calcule la densité de puissance  des appareils de chauffage .rapport entre surface chauffée et puissance de chauffage (Exemple IND-BA-117).
 * @param datasParIndex {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param datasParNom {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param ligneCourante {FormulaireDataItem} Les infos des la ligne courante.
 * @returns {number | null}
 */
const calculDensitePuissanceChauffage = (datasParIndex: dataType, datasParNom: dataType, ligneCourante: FormulaireDataItem): number | string | null => {
    let puissanceTotale: number = 0;
    let surfaceChauffee: number = 0;
    if (ligneCourante && ligneCourante.rowIndex >= 0) {
        surfaceChauffee = recupererValeurChampParNom(champs.SURFACE_CHAUFFEE, datasParNom) as number;
        const tableau = recupererTableauChampParNom(champs.EQUIPEMENT, datasParIndex);
        // Récupère le tableau de données.
        if (tableau !== null && typeof tableau !== 'undefined') {
            for (const [clef, element] of Object.entries(tableau)) {
                if ((recupererValeurChampParNom(champs.PUISSANCE_UNITAIRE_APPAREIL, element)) && (recupererValeurChampParNom(champs.NB_APPAREILS, element))) {
                    // Récupère la valeur du champ dans la ligne du tableau.
                    const puissanceUnitaire = recupererValeurChampParNom(champs.PUISSANCE_UNITAIRE_APPAREIL, element) as number;
                    const nbAppareils = recupererValeurChampParNom(champs.NB_APPAREILS, element) as number;
                    puissanceTotale += puissanceUnitaire * nbAppareils;
                }
            }
        }
    }
    return (puissanceTotale * 1000 / surfaceChauffee).toFixed(4);
};

/**
 * Calcule la puissance récupérée de la chaufferie dans une opération non étendue (Exemple IND-UT-117 ARR 14).
 * @param datasParIndex {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param datasParNom {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param ligneCourante {FormulaireDataItem} Les infos des la ligne courante.
 * @returns {number | null}
 */
const calculPuissanceChaufferieRecuperee = (datasParIndex: dataType, datasParNom: dataType, ligneCourante: FormulaireDataItem): number | string | null => {
    let puissanceCompresseur: number = 0;
    let puissanceDejaRecuperee: number = 0;
    if (ligneCourante && ligneCourante.rowIndex >= 0) {
        puissanceCompresseur = recupererValeurChampParNom(champs.PUISSANCE_ELECTRIQUE_COMPRESSEUR, datasParNom) as number;
        puissanceDejaRecuperee = recupererValeurChampParNom(champs.PUISSANCE_THERMIQUE_DEJA_RECUPEREE, datasParNom) as number;

    }
    return (puissanceCompresseur * 2) - puissanceDejaRecuperee;
};


/**
 * Calcule Le nombre de chaudière (Exemple BAR-TH-107).
 * @param datasParIndex {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param datasParNom {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param ligneCourante {FormulaireDataItem} Les infos des la ligne courante.
 * @@returns {number | null}
 */
const calculNombreChaudiere = (datasParIndex: dataType, datasParNom: dataType, ligneCourante: FormulaireDataItem): number | null => {
    if (datasParIndex) {
        const tableau = recupererTableauChampParNom(champs.EQUIPEMENT, datasParIndex);
        if (tableau && Object.keys(tableau) && Object.keys(tableau).length > 0) {
            return Object.keys(tableau).length;
        }
    }
    return null;
};

/**
 * Calcule Le nombre de chaudière (Exemple BAR-TH-107).
 * @param datasParIndex {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param datasParNom {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param ligneCourante {FormulaireDataItem} Les infos des la ligne courante.
 * @param operator  L'opérateur à appliquer.
 * @param args Les arguments à utiliser pour le calcul de la visibilité.
 * @returns {boolean} Retourne si c'est visible ou pas.
 */
const visibiliteInsideExtended = (
    datasParIndex: dataType,
    datasParNom: dataType,
    ligneCourante: FormulaireDataItem,
    operator: string,
    args: { params: Array<{ fieldName: string, operator: string, value: any }> }
): boolean => {

    try {
        if (datasParIndex
            && datasParNom
            && ligneCourante
            && ligneCourante.rowIndex >= 0
            && operator
            && args && args.params.length >= 1) {

            const tableauGlobal = recupererTableauChampParNom(champs.EQUIPEMENT, datasParIndex);
            const tableau = tableauGlobal[ligneCourante.rowIndex];
            if (operator === 'some') {
                return args.params.some((item) => {
                    // Applique le calculateur.
                    return calculate(
                        recupererValeurChampParNom(item.fieldName, tableau) || recupererValeurChampParNom(item.fieldName, datasParIndex), 
                        item.operator,
                        item.value);
                });
            } else if (operator === 'every') {
                return args.params.every((item) => {
                    // Applique le calculateur.
                    return calculate(
                        recupererValeurChampParNom(item.fieldName, tableau) || recupererValeurChampParNom(item.fieldName, datasParIndex),
                        item.operator,
                        item.value);
                });
            }

        }
        return false;
    } catch {
        return false;
    }
};
/**
 * Calculateur.
 * @param n1 Premier opérande.
 * @param operator l'opérateur.
 * @param n2 Deuxième opérande.
 * @returns {any} Résultat de calcul.
 */
const calculate = (n1: any, operator: string, n2: any): any | null => {
    const firstValue = n1;
    const secondValue = n2;
    if (operator === 'add') return parseFloat(firstValue) + parseFloat(secondValue);
    else if (operator === 'subtract') return parseFloat(firstValue) - parseFloat(secondValue);
    else if (operator === 'multiply') return parseFloat(firstValue) * parseFloat(secondValue);
    else if (operator === 'divide') return parseFloat(firstValue) / parseFloat(secondValue);
    else if (operator === '<') return parseFloat(firstValue) < parseFloat(secondValue);
    else if (operator === '<=') return parseFloat(firstValue) <= parseFloat(secondValue);
    // tslint:disable-next-line:triple-equals
    else if (operator === '==') return parseFloat(firstValue) == parseFloat(secondValue);
    else if (operator === '===') return parseFloat(firstValue) === parseFloat(secondValue);
    else if (operator === '>') return parseFloat(firstValue) < parseFloat(secondValue);
    else if (operator === '>=') return parseFloat(firstValue) <= parseFloat(secondValue);
    else return null;
}
/**
 *  Puissance Totale des Luminaires à modules LED installées (Exemple IND-BA-116).
 * @param datasParIndex {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param datasParNom {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param ligneCourante {FormulaireDataItem} Les infos des la ligne courante.
 * @returns {number | null}
 */
const calculTotaleLumieresAModulesLED = (datasParIndex: dataType, datasParNom: dataType, ligneCourante: FormulaireDataItem): number | string | null => {

    let puissanceTotale: number = 0;
  
    if (ligneCourante && ligneCourante.rowIndex >= 0) {
        const tableau = recupererTableauChampParNom(champs.EQUIPEMENT, datasParIndex);
        // Récupère le tableau de données.
        if (tableau !== null && typeof tableau !== 'undefined') {

            // Récupère la ligne correspondante..
            type tableRowType = [string, [string, dataType]];
            const entries: tableRowType[] = Object.entries(Object.entries(tableau));

            if (entries) {
                const tableRow = entries.map(([index, [, value]]) => {
                    return { index: (parseInt(index, 10)), value };
                }).find((element: { index: number, value: dataType }) => element && (element.index === ligneCourante.rowIndex)).value;

                if ((recupererValeurChampParNom(champs.PUISSANCE_UNITAIRE_LUMINAIRE_LED, tableRow)) && (recupererValeurChampParNom(champs.NB_LUMINAIRES_LED, tableRow))) {
                    // Récupère la valeur du champ dans la ligne du tableau.
                    const nbLuminaireLED = recupererValeurChampParNom(champs.NB_LUMINAIRES_LED, tableRow) as number;
                    const puissanceUnitaireLuminaireLED = recupererValeurChampParNom(champs.PUISSANCE_UNITAIRE_LUMINAIRE_LED, tableRow) as number;
                    puissanceTotale = puissanceUnitaireLuminaireLED * nbLuminaireLED;
                }
                if (puissanceTotale && puissanceTotale > 0) {
                    return (puissanceTotale).toFixed(4);
                }
            }
        }
    }

    return null;
};

/**
 *  ZNI en fonction du département.
 * @param datasParIndex {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param datasParNom {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param ligneCourante {FormulaireDataItem} Les infos des la ligne courante.
 * @returns {boolean} True si ZNI  (Outre mer + corse)
 */
const getBonificationZni = (datasParIndex: dataType, datasParNom: dataType, ligneCourante: FormulaireDataItem): string => {

    if (ligneCourante && ligneCourante.rowIndex >= 0) {

        const departement: number = parseInt(recupererValeurChampParNom(champs.NUM_DEPARTEMENT, datasParIndex) as string);
        
        const valuesDepartementsBonifications: number[] = [
            200, // 2A
            201, // 2B
            971,
            972,
            973,
            974,
            976,
            984,
            986,
            987,
            988
        ];

        return valuesDepartementsBonifications.indexOf(departement) >= 0 ? "true" : "false";
    }

    return "false";
};

/**
 * Calcul de la SOMME_PUISSANCES_FRIGORIFIQUES.
 * @param datasParIndex {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param datasParNom {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param ligneCourante {FormulaireDataItem} Les infos des la ligne courante.
 */
const fixerPacSelonPuissanceNominalePac = (datasParIndex: dataType, datasParNom: dataType, ligneCourante: FormulaireDataItem): number | string | null => {
    if (ligneCourante && ligneCourante.rowIndex >= 0) {

        const pac = recupererChampParNom(champs.PUISSANCE_PAC, datasParIndex);
        const pNominaleUnitaire: number = recupererValeurChampParNom(champs.PUISSANCE_NOMINALE_PAC, datasParIndex) as number;

        return pac.Value = pNominaleUnitaire <= 400 ? 1 : 2;
    }
    return null;
};

/**
 * Fixe la PUISSANCE_PAC en fonction de la saisie de PUISSANCE_NOMINALE_PAC.
 * @param datasParIndex {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param datasParNom {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param ligneCourante {FormulaireDataItem} Les infos des la ligne courante.
 */
const calculerSommePuissancesFrigorifiques = (datasParIndex: dataType, datasParNom: dataType, ligneCourante: FormulaireDataItem): number | string | null => {
   const puissancePositive: number = recupererValeurChampParNom(champs.PUISSANCE_FRIGORIFIQUE_POSITIVE, datasParIndex) as number;
   const puissanceNegative: number = recupererValeurChampParNom(champs.PUISSANCE_FRIGORIFIQUE_NEGATIVE, datasParIndex) as number;

    return puissancePositive - puissanceNegative;
};

/**
 * Fixe la VOLUME_TOTAL_INITIAL
 * @param datasParIndex {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param datasParNom {{ [Key: string]: FormulaireDataItem }} Toutes les données du formulaire.
 * @param ligneCourante {FormulaireDataItem} Les infos des la ligne courante.
 */
const getVolumeTotalInitial = (datasParIndex: dataType, datasParNom: dataType, ligneCourante: FormulaireDataItem): number | string | null => {
    const volumeClassiqueInitial: number = Number(recupererValeurChampParNom(champs.VOLUME_CLASSIQUE_INITIAL, datasParIndex));
    const volumePrecariteInitial: number = Number(recupererValeurChampParNom(champs.VOLUME_PRECARITE_INITIAL, datasParIndex));
    
    return volumeClassiqueInitial + volumePrecariteInitial;
};

export const catalogueFonctions: { [Key: string]: Function } = {
    calculSommePuissancesRecuperees,
    calculNombreChaudiere,
    calculCoefficientThermique,
    calculCoefficientThermiquePac,
    calculCoefficientThermiquePacBarTh166,
    calculPuissanceTotaleChauffage,
    calculDensitePuissanceChauffage,
    calculPuissanceChaufferieRecuperee,
    calculTotaleLumieresAModulesLED,
    visibiliteInsideExtended,
    getBonificationZni,
    fixerPacSelonPuissanceNominalePac,
    calculerSommePuissancesFrigorifiques,
    getVolumeTotalInitial,
};
