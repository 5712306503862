












































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import SectionComponentRow from '@/formsGenerator/configurators/SectionComponentRow.vue';
import SectionComponentRowPreview from '@/formsGenerator/configurators/SectionComponentRowPreview.vue';
import SectionComponentRowItem from '@/formsGenerator/configurators/SectionComponentRowItem.vue';
import { estVisible } from '@/formsGenerator/components/Visibilite';
import CeePDFObjectViewer from '@/components/CeePDFObjectViewer.vue';
import { ApiService } from '@/services/base/ApiService';
import { ApiHelper } from '@/services/ApiHelper';
import { FileContentResult } from '@/shared/models';
import { Operation as OperationModel } from '@/models';
import Confirm from '@/components/Confirm.vue';
import { ResultatValidationEtape } from '@/models/ResultatValidationEtape.model';


@Component({
    name: 'FormBuilderPreview',
    components: {
        ValidationProvider,
        ValidationObserver,
        SectionComponentRow,
        SectionComponentRowPreview,
        SectionComponentRowItem,
        CeePDFObjectViewer,
        Confirm
    },
})
export default class FormBuilderPreview extends Vue {
    // Form Config.
    @Prop({ default: () => ({}) }) public readonly formConfig!: any;
    // Form Data.
    @Prop({ default: () => ({}) }) public readonly formData!: any;
    // Liste message de validation de la config des champs.
    @Prop({ default: () => new Array<{ hasError: boolean; message: string | string[] }>() })
    public readonly validationsFormConfig!: Array<{ hasError: boolean; message: string | string[] }>;
    /**
     * Bus individuel de données pour chaque opération.
     */
    @Prop({ default: () => ({}) }) public readonly bus!: Vue;
    /**
     * Bus pour partager les données entre opérations.
     */
    @Prop({ default: () => ({}) }) public readonly busSharedData!: Vue;

    /**
     * Opération affichée dans ce composant, nécessaire pour certains éléments du calcul.
     */
    @Prop({ default: () => new OperationModel() }) public operation!: OperationModel;

    /**
     * Liste des champs qui partagent les données entre Opérations.
     */
    @Prop({ default: () => new Array<string>() }) public readonly fieldsNameSharedData!: string[];
    
    // L'objet $refs de Vue.js.
    public $refs!: Vue['$refs'] & {
        form: HTMLFormElement & { validate: () => boolean }
        obs: InstanceType<typeof ValidationObserver>;
        confirm: {
            open: ((title: string | null, message: string | null, options: { color?: string, width?: number, zIndex?: number }) => Promise<boolean>),
        },
    };


    // Booléen qui indique si on affiche la boite de dialogue de la preview.
    public previewPdfDialog: boolean = false;
    // Booléen qui indique la construction de la preview en cours.
    public loadingPreviewDialog: boolean = true;
    // URL de la preview.
    public urlPreviewPdf: string | null = null;
    /**
     * Permet de calculer la visibilité du champ passé en paramètre.
     */
    public estVisible(field: any, row: any = null): boolean {
        return estVisible('', field, this.formData, row, null);
    }

    /**
     * N'affiche la section que s'il y a des éléments visibles à l'intérieur.
     * @param section section à contrôler.
     */
    public afficherSection(section: any): boolean {
        return (section && section.estVisible && section.schemas && section.schemas.filter((champ: any) => this.estVisible(champ)).length);
    }

    /**
     * Clear.
     */
    public clear(): void {
        this.$nextTick(() => {
            (this.$refs.obs as InstanceType<typeof ValidationObserver>).reset();
        });
    }
    /*
     * Valider les données de formulaire.
     */
    public validerFormulaire(): Promise<boolean | ResultatValidationEtape> {
        return new Promise<boolean | ResultatValidationEtape>((resolve) => {
            (this.$refs.obs as InstanceType<typeof ValidationObserver>)
                .validate()
                .then((response: boolean | ResultatValidationEtape) => resolve(response && this.$refs.form.validate()))
                .catch(() => resolve(false));
        });
    }
    /*
     * Envoie les données.
     */
    public submit(): Promise<boolean | any> {
        return new Promise<boolean | any>((resolve) => {
            this.validerFormulaire().then((response: boolean | ResultatValidationEtape) => {
                if (response) {
                    const templateOperationId = this.formConfig.templateOperationId;
                    const apiService = new ApiService<any>(`/operation/${templateOperationId}/${this.operation.id}/calculerVolume`);
                    let formData = this.buildFormDataSansHtml(templateOperationId);
                    apiService.put(formData).then(result => {
                        resolve(result);
                    }).catch(() => {
                        resolve({ data: { data: new OperationModel(), isError: true } });
                    });
                } else {
                    resolve({ data: { data: new OperationModel(), isError: true } });
                }
            });
        });
    }
    /*
     * Récupère les données saisies du formulaire.
     */
    public getValeursSaisies(): { [key: string]: any } {
        const templateOperationId = this.formConfig.templateOperationId;
        const data = this.builFormData(templateOperationId);

        if (data && data.valeurs) {
            return data.valeurs as { [key: string]: any };
        }
        // Retourne vide.
        return {};
    }

    /*
     * Définit la précarité des opérations résidentielles.
     * Copie les valeurs de précarité de la 1ère opération résidentielle.
     */
    public updatePrecariteValues(precariteValues: { [key: string]: any }): void {
        const templateOperationId = this.formConfig.templateOperationId;
        const data = this.builFormData(templateOperationId);
        Object.keys(precariteValues).forEach((k: string) => {
            data.valeurs[k].value = precariteValues[k];
            this.busSharedData.$emit('updateFormData', {
                ...data.valeurs[k],
                field: {
                    children: []
                }
            });
        });
    }



    /*
     * Définit la valeur d'un champ.
     */
    public updateProperty(propertyName: string, value: string | number | null): void {
        const templateOperationId = this.formConfig.templateOperationId;
        const data = this.builFormData(templateOperationId);

        const propertyObject = Object.values<{ fieldName: string, indexCalculeChamp: string }>(data.valeurs).find(x => x.fieldName === propertyName);
        if (propertyObject && propertyObject.indexCalculeChamp) {
            data.valeurs[propertyObject.indexCalculeChamp].value = value;
            this.bus.$emit('updateFormData', {
                ...data.valeurs[propertyObject.indexCalculeChamp],
                field: {
                    children: []
                }
            });
        }
    }

    /*
     * Prévisualiser Attestation sur l'honneur.
     */
    public previsualiserAttestationHonneur(): void {
        const apiExport = new ApiService<any>(`/attestationHonneur/genererAttestationHonneur`);
        this.previewPdfDialog = true;
        this.urlPreviewPdf = null;
        this.loadingPreviewDialog = true;

        const templateOperationId = this.formConfig.templateOperationId;
        const formData = this.builFormData(templateOperationId);
        apiExport.post({
            operations: [
                {
                    arreteId: this.formConfig.arreteId,
                    code: this.formConfig.templateOperationCode,
                    operationValues: formData.valeurs,
                }
            ]
        }).then((reponse) => {
            this.urlPreviewPdf = ApiHelper.getUrlDownloadBlobFile(reponse.data as unknown as FileContentResult, { type: 'application/pdf' });
        }).finally(() => this.loadingPreviewDialog = false);
    }
    /*
     * Prévisualiser Attestation sur l'honneur.
     */
    public previsualiserAttestationHonneurParDossierId(dossierId: number): void {
        // Construit ApiExport.
        const apiExport = new ApiService<any>(`/attestationHonneur/telechargerTemplateAHParIdDossier`);

        this.loadingPreviewDialog = true;
        apiExport.get(dossierId).then((reponse: any) => {
            ApiHelper.createAndDownloadBlobFile(reponse.data as unknown as FileContentResult);
        }).finally(() => this.loadingPreviewDialog = false);
    }
    /*
     * Prévisualiser PNCEE.
     */
    public previsualiserPnCEE(): void {
        // Construit ApiExport.
        const apiExport = new ApiService<any>(`/pncee/genererPnceeParModel`);
        const templateOperationId = this.formConfig.templateOperationId;
        let formConfig = this.formConfig;
        // On passe listeEnumReferentiel car il est inutile pour l'appel et il rend le HS sur la PPROD et la PROD à cause du nombre d'éléments qu'il contient (blocage WAF).
        formConfig.listeEnumReferentiel = null;
        const formData = this.builFormData(templateOperationId);
        this.loadingPreviewDialog = true;
        apiExport.post({
            operations: [
                {
                    arreteId: this.formConfig.arreteId,
                    code: this.formConfig.templateOperationCode,
                    operationValues: formData.valeurs,
                    templateOperation: formConfig,
                    templateBlocs: this.formConfig.sections,
                }
            ]
        }).then((reponse) => {
            ApiHelper.createAndDownloadBlobFile(reponse.data as unknown as FileContentResult);
        }).finally(() => this.loadingPreviewDialog = false);
    }
    /*
     * Prévisualiser Données Calcul.
     */
    public previsualiserDonneesCalcul(data: any): void {

        const finalData = data.data;
        if(!data.isError) {
            const message = `Au vu des éléments saisis ce jour, le volume de la simulation est de
            <ul>
                <li><b>${finalData.volumeEtUniteCeeClassique}</b> (CEE Classique)</li>
                <li><b>${finalData.volumeEtUniteCeePrecarite}</b> (CEE Précarité)</li>
            </ul>`;
            this.$refs.confirm.open('Résultat du calcul', message, {});
        }
    }

    // Construit les données à envoyer au formulaire.
    public builFormData(templateOperationId: number): any {

        // Enrichit les données avec la visibilité de chaque champ.
        this.rapporterVisibiliteDonnees();

        // Build data.
        return {
            templateOperationId,
            valeurs: this.formData,
            dateDebutTravaux: this.operation.dateDebutTravaux,
            dateFinTravaux: this.operation.dateFinTravaux,
            dateFinTravauxPrevisionnelle: this.operation.dateFinTravauxPrevisionnelle
        };
    }

    /**
     * Données du formulaire sans l'html.
     */
    public buildFormDataSansHtml(templateOperationId: number): any {
        let formData = this.builFormData(templateOperationId);
        // On retire le HTML présent dans l'objet POST car le WAF considère ça comme une attaque XSS (On ne peut pas POST du HTML sur la pprod et la prod).
        if (formData) {
            const regex = /(&nbsp;|<([^>]+)>)/ig;
            let formDataStringified: string = JSON.stringify(formData);
            let formDataStringifiedWithoutHtml: string = formDataStringified.replace(regex, '');
            formData = JSON.parse(formDataStringifiedWithoutHtml);
        }

        return {
            formConfig: {
                arreteId: this.formConfig.arreteId,
                templateOperationCode: this.formConfig.templateOperationCode
            },
            formData: formData,
        };
    }

    // Construit la CONFIG pour les champs.
    public construitChampPreviewConfig(field: any, config: {
        sectionIndex: number;
        rowParentIndex: number;
        rowIndex: number;
        errorMessages: any;
        success: any;
        contientFieldSet: boolean;
    }): any {
        // Construit la CONFIG.
        const result = {
            field,
            sectionIndex: config.sectionIndex,
            rowParentIndex: config.rowParentIndex,
            rowIndex: 0,
            formConfig: this.formConfig,
            formData: this.formData,
            bus: this.bus,
            busSharedData: this.busSharedData,
            fieldsNameSharedData: this.fieldsNameSharedData,
            isModeTemplate: false,
            errorMessages: config.errorMessages,
            success: config.success,
            contientFieldSet: false,
        };
        return result;
    }

    /**
     * Enrichit les données du formulaire en indiquant pour chaque valeur si le champs correspondant est visible.
     */
    private rapporterVisibiliteDonnees(): void {
        this.formConfig.sections.forEach((section: any, sectionIndex: number) => {
            section.schemas.forEach((row: any, rowIndex: number) => {

                // Champs simples.
                if (!row.children.length) {
                    this.formData[sectionIndex + '_' + rowIndex + '_0'].estVisible = this.afficherSection(section) && this.estVisible(row);
                }

                // Champs extended.
                else {
                    row.children.forEach((field: any, fieldIndex: number) => {
                        const rowData = this.formData[sectionIndex + '_' + rowIndex + '_0_' + fieldIndex];
                        rowData.estVisible = this.afficherSection(section) && this.estVisible(row) && this.estVisible(field, rowData);
                    });
                }
            });
        });
    }
}

