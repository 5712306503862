











































import { Component, Prop, Vue } from 'vue-property-decorator';
import { cloneDeep } from 'lodash-es';
import FormBuilderTemplate from './template/FormBuilderTemplate.vue';
import FormBuilderPreview from '@/formsGenerator/template/FormBuilderPreview.vue';
import customValidationRules from '@/formsGenerator/components/Validations';
import { isNullOrEmpty } from '@/formsGenerator/components/Utils';
import { fields as champsImplementes } from '@/formsGenerator/components/Fields';
import { Operation as OperationModel } from '@/models';
import { estVisible } from '@/formsGenerator/components/Visibilite';
import { SecteurAsNumber } from '@/shared/enums';

interface UpdateFormType {
    estAsupprimer: boolean;
    indexCalculeChamp: string;
    fieldName: string;
    parentFieldName: string;
    sectionIndex: number;
    rowParentIndex: number;
    rowIndex: number;
    positionIndexArray: number | null;
    childrenIndex: number | null;
    nombreOccurence: number;
    field: any;
    value: any;
}

@Component({
    name: 'FormBuilder',
    components: { FormBuilderTemplate, FormBuilderPreview },
})
export default class FormBuilder extends Vue {
    @Prop({ default: 'template' }) public readonly typeTemplate!: string;
    @Prop({ default: () => ({}) }) public readonly options!: object;
    @Prop({ default: () => ({}) }) public readonly formConfig!: any;

    @Prop({ default: () => (null as unknown as []) })
    public readonly champs!: any[];

    @Prop({ default: () => (null as any) })
    public readonly receivedFormData!: any | null;
    @Prop({ default: () => (null as any) })
    public readonly previewFormData!: any | null;

    /**
     * Opération affichée dans ce composant, nécessaire pour certains éléments du calcul.
     */
    @Prop({ default: () => new OperationModel() }) public operation!: OperationModel;

    /**
     * Bus individuel de données pour chaque opération.
     */
    public bus: Vue = new Vue();
    /**
     * Bus pour partager les données entre opérations.
     */
    @Prop({ default: () => new Vue() }) public readonly busSharedData!: Vue;
    /**
     * Liste des champs qui partagent les données entre Opérations.
     */
    @Prop({ default: () => new Array<string>() }) public readonly fieldsNameSharedData!: string[];

    /**
     * Retourne le Form Data.
     */
    public formData: any | null = null;
    /**
     * Retourne le nom du template.
     */
    public get getTemplateName(): string {
        return this.typeTemplate || 'template';
    }
    /**
     * Retourne les champs filtrés.
     */
    public get champsFiltres(): any {
        return {
            baseComponents: this.champs.filter(item => champsImplementes.base.some(champ => champ.fieldType === item.code))
                .map(item => ({ code: item.code, libelle: item.libelle, group: 'Composants de base' })).reduce((map, obj) => {
                    (map as any)[obj.code] = obj;
                    return map;
                }, {}),
            advancedComponents: this.champs.filter(item => champsImplementes.advanced.some(champ => champ.fieldType === item.code))
                .map(item => ({ code: item.code, libelle: item.libelle, group: 'Composants avancés' })).reduce((map, obj) => {
                    (map as any)[obj.code] = obj;
                    return map;
                }, {}),
        };
    }
    /**
     * Validation de la CONFIG.
     */
    public get validationsFormConfig(): Array<{ hasError: boolean; message: string | string[] }> {

        const champsDisponible = this.champsDisponibleFormConfig;
        const nbChampsDisponible = champsDisponible.length;
        const nbNomChampUnique = this.champsDisponibleFormConfig.map(item => item.nom)
            .filter((value, index, self) => !isNullOrEmpty(value) && self.indexOf(value) === index)
            .length;

        return [
            {
                hasError: (nbChampsDisponible !== nbNomChampUnique),
                message: (nbChampsDisponible !== nbNomChampUnique) ? 'Les noms des champs doivent être saisis et uniques' : '',
            },
        ];
    }
    /**
     * Retourne champs disponibles dans la config.
     */
    public get champsDisponibleFormConfig(): Array<{ nom: string; label: string }> {
        if (this.formConfig !== null && typeof this.formConfig !== 'undefined' && this.formConfig.sections) {
            return this.formConfig.sections.map((section: any) => section.schemas).flat(Infinity).filter((schema: any) => schema)
                .reduce((previousValue: any, currentValue: any) => {
                    if (currentValue) {
                        const tmp = (previousValue || Array<{ nom: string; label: string }>()).concat([{ nom: currentValue.name, label: currentValue.label }]);
                        if (currentValue.children && currentValue.children.length > 0) {
                            return tmp.concat(...currentValue.children.map((child: any) => {
                                return { nom: child.name, label: child.label };
                            }));
                        }
                        return tmp;
                    }
                    return previousValue;
                }, new Array<{ nom: string; label: string }>()).flat(Infinity);
        }
        return new Array<{ nom: string; label: string }>();
    }
    /**
     * Transforme MAP en OBJECT.
     */
    public MapToObj = (map: Map<string, any>): { [Key: string]: any } => {
        const obj: { [Key: string]: any } = {};
        map.forEach((value: any, key) => obj[key] = value);
        return obj;
    }
    /**
     * Transforme OBJECT en MAP.
     */
    public ObjToMap = (obj: { [Key: string]: any }): Map<string, any> => {
        const mp = new Map<string, any>();
        Object.keys(obj).forEach(k => mp.set(k, obj[k]));
        return mp;
    }
    /**
     * Compute les données du formulaire.
     */
    public get buildedFormData(): { [Key: string]: any } {

        const mapConfig = this.buildedFormDataFromFormConfig();
        const mapData = this.buildedFormDataFromFormData();
        const keys = new Set([...Array.from(mapConfig.keys()), ...Array.from(mapData.keys())]);
        const resultat = new Map<string, any>();
        keys.forEach((key: string) => {
            resultat.set(key, ({ ...mapConfig.get(key), ...mapData.get(key) }));
        });
        return this.MapToObj(resultat);
    }
    /**
     * Compute les données du formulaire depuis la CONFIG.
     */
    public buildedFormDataFromFormConfig(): Map<string, any> {
        const result = new Map<string, any>();
        // Tableau de sections.
        if (this.formConfig.sections && this.formConfig.sections.length) {
            this.formConfig.sections.forEach((section: any, sectionIndex: number) => {
                // Tableau de schémas.
                if (section && section.schemas && section.schemas.length) {
                    section.schemas.forEach((schema: any, rowIndex: number) => {
                        const element: UpdateFormType = {
                            fieldName: schema.name,
                            parentFieldName: schema.name,
                            sectionIndex,
                            rowParentIndex: schema.rowParentIndex || rowIndex,
                            rowIndex: schema.rowParentIndex || 0,
                            field: schema,
                            value: null,
                        } as UpdateFormType;
                        const map = this.buildDataIndex(element);
                        map.forEach((value, key) => result.set(key, value));
                    });
                }
            });
        }
        return result;
    }
    /**
     * Compute les données du formulaire depuis la SOURCE DE DONNEES.
     */
    public buildedFormDataFromFormData(): Map<string, any> {
        const result = new Map<string, any>();
        if (this.formData !== null && typeof this.formData !== 'undefined') {
            for (const [key, value] of Object.entries(this.formData)) {
                result.set(key, value);
            }
        }
        return result;
    }
    /**
     * Hook qui se déclenche lorsque l'élément est crée.
     */
    public created(): void {

        if (this.receivedFormData != null
            && typeof this.receivedFormData !== 'undefined'
            && Object.keys(this.receivedFormData).length !== 0) {
            this.formData = this.receivedFormData;
        }

        if (this.previewFormData != null
            && typeof this.previewFormData !== 'undefined'
            && typeof this.previewFormData === 'object'
            && Object.keys(this.previewFormData).length !== 0) {
            this.formData = this.previewFormData;
        }

        this.bus.$on('cloneDataRenderElement', (elt: UpdateFormType) => {
            this.updateForm(elt.estAsupprimer, elt);
        });
        //
        this.bus.$on('deleteDataRenderElement', (indexes: string[]) => {
            if (indexes && indexes.length && indexes.length > 0) {
                indexes.forEach((value: string) => this.$delete(this.formData, value));
                this.formData = cloneDeep(this.$data.formData);
                this.$emit('input', this.formData);
            }
        });
        //
        this.bus.$on('updateOnePropertyValueFormData', (elt: { dataIndex: string, value: string | number | boolean | null }) => {

            const { dataIndex, value } = elt;
            if (dataIndex && value && this.formConfig && this.formData && Object.keys(this.formData).length >= 1) {
                let tempObject = this.formData[dataIndex];
                if (tempObject && Object.keys(tempObject).length >= 1) {
                    tempObject.value = value;
                    this.$set(this.formData, dataIndex, tempObject)
                }
            }
        });

        this.bus.$on('updateFormData', (elt: UpdateFormType, notifyUpdate: boolean) => {
            this.updateForm(elt.estAsupprimer, elt);
            // On notifie la modification à l'onglet opération.
            if (notifyUpdate) {
                this.$emit('updateDonneesSaisies', elt);
            }
        });

        // Pas de données partagées pour les opérations non standards.
        if (this.operation.secteurId !== SecteurAsNumber.NonStandard) {
            this.busSharedData.$on('updateFormData', (elt: UpdateFormType, notifyUpdate: boolean) => {
                this.$nextTick().then(() => {
                    this.updateForm(elt.estAsupprimer, elt)
                    // On notifie la modification à l'onglet opération.
                    if (notifyUpdate) {
                        this.$emit('updateDonneesSaisies', elt);
                    }
                });
            });
        } else {
            this.busSharedData.$on('updateValorisationNonStandard', (elt: UpdateFormType) => {
                this.$nextTick().then(() => this.updateForm(elt.estAsupprimer, elt));
            });
        }
    }
    /**
     * Hook qui se déclenche lorsque l'élément est attaché aux DOMS.
     */
    public mounted(): void {
        // Enregistre les règles de validation customs.
        customValidationRules();
    }
    /**
     * Mise à jour des données du formulaire.
     */
    public updateForm(estAsupprimer: boolean, elt: UpdateFormType): void {
        const { fieldName, parentFieldName } = elt;
        if (this.formData === null) { this.formData = {}; }
        if (fieldName && parentFieldName) {
            const map = this.buildDataIndex(elt);
            if (map && map.size && map.size > 0) {
                if (estAsupprimer) {
                    map.forEach((value, key) => this.$delete(this.formData, key));
                } else {
                    map.forEach((value, key) => {
                        this.$set(this.formData, key, value)
                    });
                }
                this.$emit('input', cloneDeep((this.formData)));
            }
        }

        // Suppression du formData des champs non visible, si besoin
        if (this.formConfig.sections) {
            this.formConfig.sections.forEach((section: any) => {
                if (section.schemas) {
                    section.schemas.forEach((schema: any) => {                          
                        // Si élément non visible
                        if (!estVisible('', schema, this.formData, null, null)) {
                            // Récupération data correspondant
                            Object.entries(this.formData).forEach(([key, champ]: [any, any]) => {                                    
                                // Si champ non visible possède une valeur dans le formData => delete
                                if (champ.fieldName === schema.name) {
                                    delete this.formData[key];
                                }
                            });
                        }
                        /********* Linked to #91366 : ********/
                        // Traitement pour les équipements
                        /*schema.children.forEach((child: any, index: number) => {
                            Object.entries(this.formData).forEach((equipementField: any) => {
                                if (equipementField && equipementField[1] && equipementField[1].fieldName === child.name) {
                                    if (!estVisible('', child, this.formData, equipementField[1], null)) {
                                        delete this.formData[equipementField[1].indexCalculeChamp];
                                    }
                                }
                            });
                        });*/
                    });
                }
            });
        }
    }

    /**
     * Construit l'index d'un bloc de champ.
     */
    public buildDataIndex(elt: UpdateFormType): Map<string, any> {
        const result = new Map<string, any>();
        if (elt && elt.field !== null) {
            const clonedField = cloneDeep(elt.field);
            const { children } = clonedField;
            if (children && children.length && children.length > 0) {
                const newRowIndex = (elt.nombreOccurence >= 0) ? (elt.nombreOccurence + 1) : 0;
                //
                children.forEach((child: any, positionIndexArray: number) => {
                    const childKey = this.buildFinalFieldIndex(elt.sectionIndex, elt.rowParentIndex, newRowIndex, positionIndexArray);
                    result.set(childKey, {
                        indexCalculeChamp: childKey,
                        parentFieldName: elt.parentFieldName,
                        fieldName: child.name,
                        sectionIndex: elt.sectionIndex,
                        rowParentIndex: elt.rowParentIndex,
                        rowIndex: newRowIndex,
                        positionIndexArray,
                        value: child.value,
                    });
                });

            } else {
                const key: string = this.buildFinalFieldIndex(elt.sectionIndex, elt.rowParentIndex, elt.rowIndex, elt.positionIndexArray);
                const value: any = {
                    indexCalculeChamp: key,
                    parentFieldName: elt.parentFieldName,
                    fieldName: elt.fieldName,
                    sectionIndex: elt.sectionIndex,
                    rowParentIndex: elt.rowParentIndex,
                    rowIndex: elt.rowIndex,
                    positionIndexArray: elt.positionIndexArray,
                    value: elt.value,
                };
                result.set(key, value);
            }
        }
        return result;
    }
    /**
     * Construit l'index d'un champ.
     */
    public buildFinalFieldIndex(sectionIndex: number, rowParentIndex: number, rowIndex: number, positionIndexArray: number | null): string {
        const listIndex = [sectionIndex, rowParentIndex, rowIndex];
        if (positionIndexArray !== null && positionIndexArray >= 0) {
            listIndex.push(positionIndexArray);
        }
        return `${listIndex.join('_')}`;
    }
    /**
     * Ajoute les données de FORM DATA dans un tableau.
     */
    public pushToArrayFormData(array: any[], fieldName: string, sectionIndex: number, rowIndex: number, obj: any): any[] {
        const myArray: any[] = (array || new Array<any>());
        const index = myArray.findIndex((item: any) => item.sectionIndex === sectionIndex && item.rowIndex === rowIndex);

        if (index === -1) {
            myArray.push(obj);
        } else {
            myArray[index] = obj;
        }
        return myArray;
    }
}
