












import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as pdfobject from 'pdfobject';
import { isUndefined } from 'lodash';

@Component({
    name: 'CeePDFObjectViewer',
    inheritAttrs: false,
})
export default class CeePDFObjectViewer extends Vue {

    @Prop({ default: '' }) public url!: string;
    @Prop({ default: 0 }) public readonly height!: number;

    // Permet de savoir si c'est un blob ou pas
    @Prop({ default: true }) public isBlob!: boolean;
    // Booléen qui indique la construction de la preview en cours.
    @Prop({ default: true }) public loading: boolean = false;

    /**
     * Permet de savoir si 'objet PDFObject est défini.
     */
    public get isPDFObjectUndefined(): boolean {
        return isUndefined(this.getWindowPDFObject);
    }
    /**
     * Les options de l'objet PDFObject.
     */
    public get optionsPDFObject(): any {
        return {
            pdfOpenParams: {
                pagemode: "thumbs",
                scrollbar: 0,
                toolbar: 1,
                statusbar: 1,
                messages: 1,
                navpanes: 1,
                view: "FitH",
            },
        };
    }
    /**
     * L'objet PDFObject est défini.
     */
    public get getWindowPDFObject(): any {
        return pdfobject;
    }

    /**
     * Hook qui se déclenche lorsque le composnant est attaché au DOM.
     */
    public mounted(): void {
        this.ConstruireLePDF();
    }
    /**
         * Watcher afin de savoir quand chercher des items.
         * @param value Valeur de l'input de recherche.
         */
    @Watch('url', { immediate: true })
    public urlChanged(val: any) {
        if (!!val) {
            this.ConstruireLePDF();
        } else {
            // On enlève l'element.
            const child = this.$el;
            // Vide le DOM.
            if (!!child) {
                while (child.firstChild && child.removeChild(child.firstChild));
            }
        }
    }
    /**
     * Insère le PDF.
     */
    public ConstruireLePDF(): void {
        if (!this.isPDFObjectUndefined && this.url) {
            this.getWindowPDFObject.embed(this.url, this.$el, this.optionsPDFObject);
        }
    }
}
